import { IconFontawesome } from '@freshsolutions/fresh-vue'

export default {
  'action-show-history': {
    component: IconFontawesome,
    props: {
      type: 'layers',
      icon: 'circle',
      iconMask: 'clock-rotate-left',
      transform: 'down-6 right-6',
      iconChildren: [
        {
          icon: 'eye',
          transform: 'shrink-6 down-6 right-6'
        }
      ]
    }
  },

  // Filters
  'history-filter-EDIT': {
    component: IconFontawesome,
    props: {
      icon: 'pen'
    }
  },
  'history-filter-NOTE': {
    component: IconFontawesome,
    props: {
      icon: 'sticky-note'
    }
  },
  'history-filter-COMMUNICATION_PHONE': {
    component: IconFontawesome,
    props: {
      icon: 'phone'
    }
  },
  'history-filter-COMMUNICATION_MAIL': {
    component: IconFontawesome,
    props: {
      icon: 'envelope'
    }
  },
  'history-filter-VEHICLE_SALE': {
    component: IconFontawesome,
    props: {
      icon: 'shopping-cart'
    }
  },

  // Events
  'history-event-CREATED': {
    component: IconFontawesome,
    props: {
      icon: 'plus'
    }
  },
  'history-event-UPDATED': {
    component: IconFontawesome,
    props: {
      icon: 'arrows-rotate'
    }
  },
  'history-event-DELETED': {
    component: IconFontawesome,
    props: {
      icon: 'trash'
    }
  },
  'history-event-RESTORED': {
    component: IconFontawesome,
    props: {
      icon: 'trash-arrow-up'
    }
  },
  'history-event-NOTE_CREATED': {
    component: IconFontawesome,
    props: {
      icon: 'sticky-note'
    }
  },
  'history-event-NOTE_DELETED': {
    component: IconFontawesome,
    props: {
      type: 'layers',
      icon: 'circle',
      iconMask: 'sticky-note',
      transform: 'down-6 right-6',
      iconChildren: [
        {
          icon: 'trash',
          transform: 'shrink-6 down-6 right-6'
        }
      ]
    }
  },
  'history-event-MEETING_CREATED': {
    component: IconFontawesome,
    props: {
      icon: 'calendar-day'
    }
  },
  'history-event-MEETING_DELETED': {
    component: IconFontawesome,
    props: {
      type: 'layers',
      icon: 'circle',
      iconMask: 'calendar-day',
      transform: 'down-6 right-6',
      iconChildren: [
        {
          icon: 'trash',
          transform: 'shrink-6 down-6 right-6'
        }
      ]
    }
  },

  // DAMAGE
  'history-event-DAMAGE_INPUT_VUDMGID_MANUAL': {
    component: IconFontawesome,
    props: {
      icon: 'shield-blank'
    }
  },
  'history-event-DAMAGE_INPUT_VUDMGID_AUTOMATIC': {
    component: IconFontawesome,
    props: {
      icon: 'shield-blank'
    }
  },
  'history-event-DAMAGE_SENT_VU': {
    component: IconFontawesome,
    props: {
      icon: 'paper-plane'
    }
  },
  'history-event-DAMAGE_CLOSED': {
    component: IconFontawesome,
    props: {
      icon: 'check'
    }
  },
  'history-event-DAMAGE_GENERATE_PDF': {
    component: IconFontawesome,
    props: {
      icon: 'file-pdf'
    }
  },
  'history-event-DAMAGE_CONTACT_VU_EMAIL': {
    component: IconFontawesome,
    props: {
      icon: 'paper-plane'
    }
  },
  'history-event-DAMAGE_CONTACT_PERSON_EMAIL': {
    component: IconFontawesome,
    props: {
      icon: 'paper-plane'
    }
  },
  'history-event-DAMAGE_RECEIVED_EMAIL': {
    component: IconFontawesome,
    props: {
      icon: 'envelope'
    }
  },
  'history-event-CLAIMSTATEMENT_CREATED': {
    component: IconFontawesome,
    props: {
      type: 'layers',
      icon: 'circle',
      iconMask: 'comment',
      transform: 'down-8 right-8',
      iconChildren: [
        {
          icon: 'paper-plane',
          transform: 'shrink-8 down-8 right-8'
        }
      ]
    }
  },
  'history-event-CLAIMSTATEMENT_SUBMITTED': {
    component: IconFontawesome,
    props: {
      type: 'layers',
      icon: 'circle',
      iconMask: 'comment',
      transform: 'down-8 right-8',
      iconChildren: [
        {
          icon: 'check',
          transform: 'shrink-8 down-8 right-8'
        }
      ]
    }
  },
  'history-event-CLAIMSTATEMENT_DELETED': {
    component: IconFontawesome,
    props: {
      type: 'layers',
      icon: 'circle',
      iconMask: 'comment',
      transform: 'down-8 right-8',
      iconChildren: [
        {
          icon: 'trash',
          transform: 'shrink-8 down-8 right-8'
        }
      ]
    }
  },

  // Schadenmeldung Claim freshBIKE
  'history-event-FRESHBIKE_CLAIM_INPUT_VUCLAIMID_MANUAL': {
    component: IconFontawesome,
    props: {
      icon: 'shield-blank'
    }
  },
  'history-event-FRESHBIKE_CLAIM_INPUT_VUCLAIMID_AUTOMATIC': {
    component: IconFontawesome,
    props: {
      icon: 'shield-blank'
    }
  },
  'history-event-FRESHBIKE_CLAIM_SENT_FFF': {
    component: IconFontawesome,
    props: {
      icon: 'paper-plane'
    }
  },
  'history-event-FRESHBIKE_CLAIM_SENT_VU': {
    component: IconFontawesome,
    props: {
      icon: 'paper-plane'
    }
  },
  'history-event-FRESHBIKE_CLAIM_CLOSED': {
    component: IconFontawesome,
    props: {
      icon: 'check'
    }
  },
  'history-event-FRESHBIKE_CLAIM_GENERATE_PDF': {
    component: IconFontawesome,
    props: {
      icon: 'file-pdf'
    }
  },
  'history-event-FRESHBIKE_CLAIM_CONTACT_VU_EMAIL': {
    component: IconFontawesome,
    props: {
      icon: 'paper-plane'
    }
  },
  'history-event-FRESHBIKE_CLAIM_RECEIVED_EMAIL': {
    component: IconFontawesome,
    props: {
      icon: 'envelope'
    }
  },

  // CUSTOMER
  'history-event-CUSTOMER_EXTERNAL_IMPORT': {
    component: IconFontawesome,
    props: {
      icon: 'file-import'
    }
  },
  'history-event-CUSTOMER_GENERATE_PERSONAL_DATA_PDF': {
    component: IconFontawesome,
    props: {
      type: 'layers',
      icon: 'circle',
      iconMask: 'database',
      transform: 'down-8 right-8',
      iconChildren: [
        {
          icon: 'plus',
          transform: 'shrink-8 down-8 right-8'
        }
      ]
    }
  },
  'history-event-CUSTOMER_SEND_PERSONAL_DATA_PDF': {
    component: IconFontawesome,
    props: {
      type: 'layers',
      icon: 'circle',
      iconMask: 'database',
      transform: 'down-8 right-8',
      iconChildren: [
        {
          icon: 'paper-plane',
          transform: 'shrink-8 down-8 right-8'
        }
      ]
    }
  },
  'history-event-CUSTOMER_GENERATE_PRIVACY_POLICY_PDF': {
    component: IconFontawesome,
    props: {
      type: 'layers',
      icon: 'circle',
      iconMask: 'file-contract',
      transform: 'down-8 right-8',
      iconChildren: [
        {
          icon: 'plus',
          transform: 'shrink-8 down-8 right-8'
        }
      ]
    }
  },
  'history-event-CUSTOMER_GENERATE_PRIVACY_POLICY_URL': {
    component: IconFontawesome,
    props: {
      type: 'layers',
      icon: 'circle',
      iconMask: 'file-contract',
      transform: 'down-8 right-8',
      iconChildren: [
        {
          icon: 'link',
          transform: 'shrink-8 down-8 right-8'
        }
      ]
    }
  },
  'history-event-CUSTOMER_SEND_PRIVACY_POLICY_PDF': {
    component: IconFontawesome,
    props: {
      type: 'layers',
      icon: 'circle',
      iconMask: 'file-contract',
      transform: 'down-8 right-8',
      iconChildren: [
        {
          icon: 'paper-plane',
          transform: 'shrink-8 down-8 right-8'
        }
      ]
    }
  },
  'history-event-CUSTOMER_SIGNED_PRIVACY_POLICY_PDF': {
    component: IconFontawesome,
    props: {
      type: 'layers',
      icon: 'circle',
      iconMask: 'file-contract',
      transform: 'down-8 right-8',
      iconChildren: [
        {
          icon: 'pen',
          transform: 'shrink-8 down-8 right-8'
        }
      ]
    }
  },
  'history-event-CUSTOMER_CREATE_TESTDRIVECONTRACT_PDF': {
    component: IconFontawesome,
    props: {
      type: 'layers',
      icon: 'circle',
      iconMask: 'file-contract',
      transform: 'down-8 right-8',
      iconChildren: [
        {
          icon: 'car',
          transform: 'shrink-8 down-8 right-8'
        }
      ]
    }
  },
  'history-event-CUSTOMER_GENERATE_TESTDRIVECONTRACT_URL': {
    component: IconFontawesome,
    props: {
      type: 'layers',
      icon: 'circle',
      iconMask: 'file-contract',
      transform: 'down-8 right-8',
      iconChildren: [
        {
          icon: 'link',
          transform: 'shrink-8 down-8 right-8'
        }
      ]
    }
  },
  'history-event-CUSTOMER_SEND_TESTDRIVECONTRACT_PDF': {
    component: IconFontawesome,
    props: {
      type: 'layers',
      icon: 'circle',
      iconMask: 'file-contract',
      transform: 'down-8 right-8',
      iconChildren: [
        {
          icon: 'paper-plane',
          transform: 'shrink-8 down-8 right-8'
        }
      ]
    }
  },
  'history-event-CUSTOMER_SIGNED_TESTDRIVECONTRACT_PDF': {
    component: IconFontawesome,
    props: {
      type: 'layers',
      icon: 'circle',
      iconMask: 'file-contract',
      transform: 'down-8 right-8',
      iconChildren: [
        {
          icon: 'pen',
          transform: 'shrink-8 down-8 right-8'
        }
      ]
    }
  },
  'history-event-CUSTOMER_CREATE_RENTALDRIVECONTRACT_PDF': {
    component: IconFontawesome,
    props: {
      type: 'layers',
      icon: 'circle',
      iconMask: 'file-contract',
      transform: 'down-8 right-8',
      iconChildren: [
        {
          icon: 'car',
          transform: 'shrink-8 down-8 right-8'
        }
      ]
    }
  },
  'history-event-CUSTOMER_GENERATE_RENTALDRIVECONTRACT_URL': {
    component: IconFontawesome,
    props: {
      type: 'layers',
      icon: 'circle',
      iconMask: 'file-contract',
      transform: 'down-8 right-8',
      iconChildren: [
        {
          icon: 'link',
          transform: 'shrink-8 down-8 right-8'
        }
      ]
    }
  },
  'history-event-CUSTOMER_SEND_RENTALDRIVECONTRACT_PDF': {
    component: IconFontawesome,
    props: {
      type: 'layers',
      icon: 'circle',
      iconMask: 'file-contract',
      transform: 'down-8 right-8',
      iconChildren: [
        {
          icon: 'paper-plane',
          transform: 'shrink-8 down-8 right-8'
        }
      ]
    }
  },
  'history-event-CUSTOMER_SIGNED_RENTALDRIVECONTRACT_PDF': {
    component: IconFontawesome,
    props: {
      type: 'layers',
      icon: 'circle',
      iconMask: 'file-contract',
      transform: 'down-8 right-8',
      iconChildren: [
        {
          icon: 'pen',
          transform: 'shrink-8 down-8 right-8'
        }
      ]
    }
  },
  'history-event-CUSTOMER_ADDRESS_CREATED': {
    component: IconFontawesome,
    props: {
      type: 'layers',
      icon: 'circle',
      iconMask: 'location-pin',
      transform: 'down-6 right-6',
      iconChildren: [
        {
          icon: 'plus',
          transform: 'shrink-6 down-6 right-6'
        }
      ]
    }
  },
  'history-event-CUSTOMER_ADDRESS_UPDATED': {
    component: IconFontawesome,
    props: {
      type: 'layers',
      icon: 'circle',
      iconMask: 'location-pin',
      transform: 'down-6 right-6',
      iconChildren: [
        {
          icon: 'arrows-rotate',
          transform: 'shrink-6 down-6 right-6'
        }
      ]
    }
  },
  'history-event-CUSTOMER_ADDRESS_DELETED': {
    component: IconFontawesome,
    props: {
      type: 'layers',
      icon: 'circle',
      iconMask: 'location-pin',
      transform: 'down-6 right-6',
      iconChildren: [
        {
          icon: 'trash',
          transform: 'shrink-6 down-6 right-6'
        }
      ]
    }
  },
  'history-event-CUSTOMER_ADDRESS_RESTORED': {
    component: IconFontawesome,
    props: {
      type: 'layers',
      icon: 'circle',
      iconMask: 'location-pin',
      transform: 'down-6 right-6',
      iconChildren: [
        {
          icon: 'trash-arrow-up',
          transform: 'shrink-6 down-6 right-6'
        }
      ]
    }
  },
  'history-event-CUSTOMER_COMMUNICATION_CREATED': {
    component: IconFontawesome,
    props: {
      type: 'layers',
      icon: 'circle',
      iconMask: 'phone',
      transform: 'down-6 right-6',
      iconChildren: [
        {
          icon: 'plus',
          transform: 'shrink-6 down-6 right-6'
        }
      ]
    }
  },
  'history-event-CUSTOMER_COMMUNICATION_UPDATED': {
    component: IconFontawesome,
    props: {
      type: 'layers',
      icon: 'circle',
      iconMask: 'phone',
      transform: 'down-6 right-6',
      iconChildren: [
        {
          icon: 'arrows-rotate',
          transform: 'shrink-6 down-6 right-6'
        }
      ]
    }
  },
  'history-event-CUSTOMER_COMMUNICATION_DELETED': {
    component: IconFontawesome,
    props: {
      type: 'layers',
      icon: 'circle',
      iconMask: 'phone',
      transform: 'down-6 right-6',
      iconChildren: [
        {
          icon: 'trash',
          transform: 'shrink-6 down-6 right-6'
        }
      ]
    }
  },
  'history-event-CUSTOMER_COMMUNICATION_RESTORED': {
    component: IconFontawesome,
    props: {
      type: 'layers',
      icon: 'circle',
      iconMask: 'phone',
      transform: 'down-6 right-6',
      iconChildren: [
        {
          icon: 'trash-arrow-up',
          transform: 'shrink-6 down-6 right-6'
        }
      ]
    }
  },
  'history-event-CUSTOMER_BANKDETAIL_CREATED': {
    component: IconFontawesome,
    props: {
      type: 'layers',
      icon: 'circle',
      iconMask: 'bank',
      transform: 'down-6 right-6',
      iconChildren: [
        {
          icon: 'plus',
          transform: 'shrink-6 down-6 right-6'
        }
      ]
    }
  },
  'history-event-CUSTOMER_BANKDETAIL_UPDATED': {
    component: IconFontawesome,
    props: {
      type: 'layers',
      icon: 'circle',
      iconMask: 'bank',
      transform: 'down-6 right-6',
      iconChildren: [
        {
          icon: 'arrows-rotate',
          transform: 'shrink-6 down-6 right-6'
        }
      ]
    }
  },
  'history-event-CUSTOMER_BANKDETAIL_DELETED': {
    component: IconFontawesome,
    props: {
      type: 'layers',
      icon: 'circle',
      iconMask: 'bank',
      transform: 'down-6 right-6',
      iconChildren: [
        {
          icon: 'trash',
          transform: 'shrink-6 down-6 right-6'
        }
      ]
    }
  },
  'history-event-CUSTOMER_BANKDETAIL_RESTORED': {
    component: IconFontawesome,
    props: {
      type: 'layers',
      icon: 'circle',
      iconMask: 'bank',
      transform: 'down-6 right-6',
      iconChildren: [
        {
          icon: 'trash-arrow-up',
          transform: 'shrink-6 down-6 right-6'
        }
      ]
    }
  },
  'history-event-CUSTOMER_CONTACTPERSON_CREATED': {
    component: IconFontawesome,
    props: {
      type: 'layers',
      icon: 'circle',
      iconMask: 'user',
      transform: 'down-6 right-6',
      iconChildren: [
        {
          icon: 'plus',
          transform: 'shrink-6 down-6 right-6'
        }
      ]
    }
  },
  'history-event-CUSTOMER_CONTACTPERSON_UPDATED': {
    component: IconFontawesome,
    props: {
      type: 'layers',
      icon: 'circle',
      iconMask: 'user',
      transform: 'down-6 right-6',
      iconChildren: [
        {
          icon: 'arrows-rotate',
          transform: 'shrink-6 down-6 right-6'
        }
      ]
    }
  },
  'history-event-CUSTOMER_CONTACTPERSON_DELETED': {
    component: IconFontawesome,
    props: {
      type: 'layers',
      icon: 'circle',
      iconMask: 'user',
      transform: 'down-6 right-6',
      iconChildren: [
        {
          icon: 'trash',
          transform: 'shrink-6 down-6 right-6'
        }
      ]
    }
  },
  'history-event-CUSTOMER_CONTACT_EMAIL': {
    component: IconFontawesome,
    props: {
      type: 'layers',
      icon: 'circle',
      iconMask: 'envelope',
      transform: 'down-8 right-8',
      iconChildren: [
        {
          icon: 'paper-plane',
          transform: 'shrink-8 down-8 right-8'
        }
      ]
    }
  },
  'history-event-CUSTOMER_CONTACT_PHONE': {
    component: IconFontawesome,
    props: {
      type: 'layers',
      icon: 'circle',
      iconMask: 'phone',
      transform: 'down-8 right-8',
      iconChildren: [
        {
          icon: 'paper-plane',
          transform: 'shrink-8 down-8 right-8'
        }
      ]
    }
  },
  'history-event-CUSTOMER_RECEIVED_EMAIL': {
    component: IconFontawesome,
    props: {
      icon: 'envelope'
    }
  },
  'history-event-CUSTOMER_SEND_BIRTHDAY_GREETINGS': {
    component: IconFontawesome,
    props: {
      type: 'layers',
      icon: 'circle',
      iconMask: 'cake-candles',
      transform: 'down-8 right-8',
      iconChildren: [
        {
          icon: 'paper-plane',
          transform: 'shrink-8 down-8 right-8'
        }
      ]
    }
  },
  'history-event-FORMREQUEST_CREATED': {
    component: IconFontawesome,
    props: {
      type: 'layers',
      icon: 'circle',
      iconMask: 'file-pen',
      transform: 'down-8 right-8',
      iconChildren: [
        {
          icon: 'paper-plane',
          transform: 'shrink-8 down-8 right-8'
        }
      ]
    }
  },
  'history-event-FORMREQUEST_SUBMITTED': {
    component: IconFontawesome,
    props: {
      type: 'layers',
      icon: 'circle',
      iconMask: 'file-pen',
      transform: 'down-8 right-8',
      iconChildren: [
        {
          icon: 'check',
          transform: 'shrink-8 down-8 right-8'
        }
      ]
    }
  },
  'history-event-FORMREQUEST_DELETED': {
    component: IconFontawesome,
    props: {
      type: 'layers',
      icon: 'circle',
      iconMask: 'file-pen',
      transform: 'down-8 right-8',
      iconChildren: [
        {
          icon: 'trash',
          transform: 'shrink-8 down-8 right-8'
        }
      ]
    }
  },

  // OFFER
  'history-event-OFFER_GENERATE_EXPOSE_PDF': {
    component: IconFontawesome,
    props: {
      icon: 'file-pdf'
    }
  },
  'history-event-OFFER_GENERATE_PRICE_PDF': {
    component: IconFontawesome,
    props: {
      icon: 'file-pdf'
    }
  },

  'history-event-OFFER_FLYER_CREATED': {
    component: IconFontawesome,
    props: {
      type: 'layers',
      icon: 'circle',
      iconMask: 'file-pdf',
      transform: 'down-6 right-6',
      iconChildren: [
        {
          icon: 'plus',
          transform: 'shrink-6 down-6 right-6'
        }
      ]
    }
  },
  'history-event-OFFER_FLYER_UPDATED': {
    component: IconFontawesome,
    props: {
      type: 'layers',
      icon: 'circle',
      iconMask: 'file-pdf',
      transform: 'down-6 right-6',
      iconChildren: [
        {
          icon: 'arrows-rotate',
          transform: 'shrink-6 down-6 right-6'
        }
      ]
    }
  },

  'history-event-OFFER_PRICES_CHANGED': {
    component: IconFontawesome,
    props: {
      icon: 'money-bill'
    }
  },
  'history-event-OFFER_PRICES_CHANGED_JOB': {
    component: IconFontawesome,
    props: {
      icon: 'money-bill'
    }
  },

  'history-event-OFFER_CONTRACT_CREATED': {
    component: IconFontawesome,
    props: {
      type: 'layers',
      icon: 'circle',
      iconMask: 'file-signature',
      transform: 'down-6 right-6',
      iconChildren: [
        {
          icon: 'plus',
          transform: 'shrink-6 down-6 right-6'
        }
      ]
    }
  },
  'history-event-OFFER_CONTRACT_URL_CREATED': {
    component: IconFontawesome,
    props: {
      type: 'layers',
      icon: 'circle',
      iconMask: 'file-signature',
      transform: 'down-6 right-6',
      iconChildren: [
        {
          icon: 'link',
          transform: 'shrink-6 down-6 right-6'
        }
      ]
    }
  },
  'history-event-OFFER_CONTRACT_SIGNED': {
    component: IconFontawesome,
    props: {
      type: 'layers',
      icon: 'circle',
      iconMask: 'file-signature',
      transform: 'down-6 right-6',
      iconChildren: [
        {
          icon: 'pen',
          transform: 'shrink-6 down-6 right-6'
        }
      ]
    }
  },
  'history-event-OFFER_CONTRACT_CLOSED': {
    component: IconFontawesome,
    props: {
      type: 'layers',
      icon: 'circle',
      iconMask: 'file-signature',
      transform: 'down-6 right-6',
      iconChildren: [
        {
          icon: 'check',
          transform: 'shrink-6 down-6 right-6'
        }
      ]
    }
  },
  'history-event-OFFER_CONTRACT_CANCELED': {
    component: IconFontawesome,
    props: {
      type: 'layers',
      icon: 'circle',
      iconMask: 'file-signature',
      transform: 'down-6 right-6',
      iconChildren: [
        {
          icon: 'trash',
          transform: 'shrink-6 down-6 right-6'
        }
      ]
    }
  },
  'history-event-OFFER_CONTRACT_REOPENED': {
    component: IconFontawesome,
    props: {
      type: 'layers',
      icon: 'circle',
      iconMask: 'file-signature',
      transform: 'down-6 right-6',
      iconChildren: [
        {
          icon: 'trash-arrow-up',
          transform: 'shrink-6 down-6 right-6'
        }
      ]
    }
  },
  'history-event-OFFER_CONTRACT_SEND': {
    component: IconFontawesome,
    props: {
      type: 'layers',
      icon: 'circle',
      iconMask: 'file-signature',
      transform: 'down-6 right-6',
      iconChildren: [
        {
          icon: 'paper-plane',
          transform: 'shrink-6 down-6 right-6'
        }
      ]
    }
  },
  'history-event-OFFER_CONTRACTOFFER_SEND': {
    component: IconFontawesome,
    props: {
      type: 'layers',
      icon: 'circle',
      iconMask: 'file-signature',
      transform: 'down-6 right-6',
      iconChildren: [
        {
          icon: 'paper-plane',
          transform: 'shrink-6 down-6 right-6'
        }
      ]
    }
  },
  'history-event-OFFER_CONTRACTOFFER_URL_CREATED': {
    component: IconFontawesome,
    props: {
      type: 'layers',
      icon: 'circle',
      iconMask: 'file-signature',
      transform: 'down-6 right-6',
      iconChildren: [
        {
          icon: 'link',
          transform: 'shrink-6 down-6 right-6'
        }
      ]
    }
  },
  'history-event-OFFER_CONTRACTOFFER_SIGNED': {
    component: IconFontawesome,
    props: {
      type: 'layers',
      icon: 'circle',
      iconMask: 'file-signature',
      transform: 'down-6 right-6',
      iconChildren: [
        {
          icon: 'pen',
          transform: 'shrink-6 down-6 right-6'
        }
      ]
    }
  },
  'history-event-OFFER_ORDERCONFIRMATION_SEND': {
    component: IconFontawesome,
    props: {
      type: 'layers',
      icon: 'circle',
      iconMask: 'clipboard',
      transform: 'down-6 right-6',
      iconChildren: [
        {
          icon: 'paper-plane',
          transform: 'shrink-6 down-6 right-6'
        }
      ]
    }
  },
  'history-event-OFFER_PUBLISH': {
    component: IconFontawesome,
    props: {
      type: 'layers',
      icon: 'circle',
      iconMask: 'store',
      transform: 'down-6 right-6',
      iconChildren: [
        {
          icon: 'paper-plane',
          transform: 'shrink-6 down-6 right-6'
        }
      ]
    }
  },
  'history-event-OFFER_UNPUBLISH': {
    component: IconFontawesome,
    props: {
      type: 'layers',
      icon: 'circle',
      iconMask: 'store',
      transform: 'down-6 right-6',
      iconChildren: [
        {
          icon: 'trash',
          transform: 'shrink-6 down-6 right-6'
        }
      ]
    }
  },

  // VEHICLE
  'history-event-VEHICLE_EXTERNAL_IMPORT': {
    component: IconFontawesome,
    props: {
      icon: 'file-import'
    }
  },
  'history-event-VEHICLE_GENERATE_ENERGYLABEL_PDF': {
    component: IconFontawesome,
    props: {
      icon: 'file-pdf'
    }
  },
  'history-event-VEHICLE_RESERVATION_CREATED': {
    component: IconFontawesome,
    props: {
      icon: 'tag'
    }
  },
  'history-event-VEHICLE_RESERVATION_CANCELED': {
    component: IconFontawesome,
    props: {
      icon: 'tag'
    }
  },
  'history-event-VEHICLE_PURCHASE_CREATED': {
    component: IconFontawesome,
    props: {
      type: 'layers',
      icon: 'circle',
      iconMask: 'file-signature',
      transform: 'down-6 right-6',
      iconChildren: [
        {
          icon: 'copy',
          transform: 'shrink-6 down-6 right-6'
        }
      ]
    }
  },
  'history-event-VEHICLE_PURCHASE_URL_CREATED': {
    component: IconFontawesome,
    props: {
      type: 'layers',
      icon: 'circle',
      iconMask: 'file-signature',
      transform: 'down-6 right-6',
      iconChildren: [
        {
          icon: 'link',
          transform: 'shrink-6 down-6 right-6'
        }
      ]
    }
  },
  'history-event-VEHICLE_PURCHASE_CLOSED': {
    component: IconFontawesome,
    props: {
      type: 'layers',
      icon: 'circle',
      iconMask: 'file-signature',
      transform: 'down-6 right-6',
      iconChildren: [
        {
          icon: 'check',
          transform: 'shrink-6 down-6 right-6'
        }
      ]
    }
  },
  'history-event-VEHICLE_PURCHASE_CANCELED': {
    component: IconFontawesome,
    props: {
      type: 'layers',
      icon: 'circle',
      iconMask: 'file-signature',
      transform: 'down-6 right-6',
      iconChildren: [
        {
          icon: 'trash',
          transform: 'shrink-6 down-6 right-6'
        }
      ]
    }
  },
  'history-event-VEHICLE_PURCHASE_REOPENED': {
    component: IconFontawesome,
    props: {
      type: 'layers',
      icon: 'circle',
      iconMask: 'file-signature',
      transform: 'down-6 right-6',
      iconChildren: [
        {
          icon: 'trash-arrow-up',
          transform: 'shrink-6 down-6 right-6'
        }
      ]
    }
  },
  'history-event-VEHICLE_PURCHASE_SEND': {
    component: IconFontawesome,
    props: {
      type: 'layers',
      icon: 'circle',
      iconMask: 'file-signature',
      transform: 'down-6 right-6',
      iconChildren: [
        {
          icon: 'paper-plane',
          transform: 'shrink-6 down-6 right-6'
        }
      ]
    }
  },
  'history-event-VEHICLE_SIGNED_PURCHASE': {
    component: IconFontawesome,
    props: {
      type: 'layers',
      icon: 'circle',
      iconMask: 'file-contract',
      transform: 'down-8 right-8',
      iconChildren: [
        {
          icon: 'pen',
          transform: 'shrink-8 down-8 right-8'
        }
      ]
    }
  },
  'history-event-VEHICLE_PURCHASEOFFER_SEND': {
    component: IconFontawesome,
    props: {
      type: 'layers',
      icon: 'circle',
      iconMask: 'file-signature',
      transform: 'down-6 right-6',
      iconChildren: [
        {
          icon: 'paper-plane',
          transform: 'shrink-6 down-6 right-6'
        }
      ]
    }
  },
  'history-event-VEHICLE_PURCHASEOFFER_URL_CREATED': {
    component: IconFontawesome,
    props: {
      type: 'layers',
      icon: 'circle',
      iconMask: 'file-signature',
      transform: 'down-6 right-6',
      iconChildren: [
        {
          icon: 'link',
          transform: 'shrink-6 down-6 right-6'
        }
      ]
    }
  },
  'history-event-VEHICLE_SIGNED_PURCHASEOFFER': {
    component: IconFontawesome,
    props: {
      type: 'layers',
      icon: 'circle',
      iconMask: 'file-contract',
      transform: 'down-8 right-8',
      iconChildren: [
        {
          icon: 'pen',
          transform: 'shrink-8 down-8 right-8'
        }
      ]
    }
  },
  'history-event-VEHICLE_PROFIT_CREATED': {
    component: IconFontawesome,
    props: {
      type: 'layers',
      icon: 'circle',
      iconMask: 'money-bill',
      transform: 'down-6 right-6',
      iconChildren: [
        {
          icon: 'plus',
          transform: 'shrink-6 down-6 right-6'
        }
      ]
    }
  },
  'history-event-VEHICLE_PROFIT_UPDATED': {
    component: IconFontawesome,
    props: {
      type: 'layers',
      icon: 'circle',
      iconMask: 'money-bill',
      transform: 'down-6 right-6',
      iconChildren: [
        {
          icon: 'arrows-rotate',
          transform: 'shrink-6 down-6 right-6'
        }
      ]
    }
  },
  'history-event-VEHICLE_PROFIT_DELETED': {
    component: IconFontawesome,
    props: {
      type: 'layers',
      icon: 'circle',
      iconMask: 'money-bill',
      transform: 'down-6 right-6',
      iconChildren: [
        {
          icon: 'trash',
          transform: 'shrink-6 down-6 right-6'
        }
      ]
    }
  },
  'history-event-VEHICLE_HUAU_REMINDER_SEND': {
    component: IconFontawesome,
    props: {
      icon: 'bell'
    }
  },
  'history-event-VEHICLE_INSPECTION_REMINDER_SEND': {
    component: IconFontawesome,
    props: {
      icon: 'bell'
    }
  },
  'history-event-VEHICLE_CREATE_TESTDRIVECONTRACT_PDF': {
    component: IconFontawesome,
    props: {
      type: 'layers',
      icon: 'circle',
      iconMask: 'file-contract',
      transform: 'down-8 right-8',
      iconChildren: [
        {
          icon: 'car',
          transform: 'shrink-8 down-8 right-8'
        }
      ]
    }
  },
  'history-event-VEHICLE_GENERATE_TESTDRIVECONTRACT_URL': {
    component: IconFontawesome,
    props: {
      type: 'layers',
      icon: 'circle',
      iconMask: 'file-contract',
      transform: 'down-8 right-8',
      iconChildren: [
        {
          icon: 'link',
          transform: 'shrink-8 down-8 right-8'
        }
      ]
    }
  },
  'history-event-VEHICLE_SEND_TESTDRIVECONTRACT_PDF': {
    component: IconFontawesome,
    props: {
      type: 'layers',
      icon: 'circle',
      iconMask: 'file-contract',
      transform: 'down-8 right-8',
      iconChildren: [
        {
          icon: 'paper-plane',
          transform: 'shrink-8 down-8 right-8'
        }
      ]
    }
  },
  'history-event-VEHICLE_SIGNED_TESTDRIVECONTRACT': {
    component: IconFontawesome,
    props: {
      type: 'layers',
      icon: 'circle',
      iconMask: 'file-contract',
      transform: 'down-8 right-8',
      iconChildren: [
        {
          icon: 'pen',
          transform: 'shrink-8 down-8 right-8'
        }
      ]
    }
  },
  'history-event-VEHICLE_CREATE_RENTALDRIVECONTRACT_PDF': {
    component: IconFontawesome,
    props: {
      type: 'layers',
      icon: 'circle',
      iconMask: 'file-contract',
      transform: 'down-8 right-8',
      iconChildren: [
        {
          icon: 'car',
          transform: 'shrink-8 down-8 right-8'
        }
      ]
    }
  },
  'history-event-VEHICLE_GENERATE_RENTALDRIVECONTRACT_URL': {
    component: IconFontawesome,
    props: {
      type: 'layers',
      icon: 'circle',
      iconMask: 'file-contract',
      transform: 'down-8 right-8',
      iconChildren: [
        {
          icon: 'link',
          transform: 'shrink-8 down-8 right-8'
        }
      ]
    }
  },
  'history-event-VEHICLE_SEND_RENTALDRIVECONTRACT_PDF': {
    component: IconFontawesome,
    props: {
      type: 'layers',
      icon: 'circle',
      iconMask: 'file-contract',
      transform: 'down-8 right-8',
      iconChildren: [
        {
          icon: 'paper-plane',
          transform: 'shrink-8 down-8 right-8'
        }
      ]
    }
  },
  'history-event-VEHICLE_SIGNED_RENTALDRIVECONTRACT': {
    component: IconFontawesome,
    props: {
      type: 'layers',
      icon: 'circle',
      iconMask: 'file-contract',
      transform: 'down-8 right-8',
      iconChildren: [
        {
          icon: 'pen',
          transform: 'shrink-8 down-8 right-8'
        }
      ]
    }
  },
  'history-event-VEHICLE_INVOICE_CREATED': {
    component: IconFontawesome,
    props: {
      type: 'layers',
      icon: 'circle',
      iconMask: 'file-invoice',
      transform: 'down-6 right-6',
      iconChildren: [
        {
          icon: 'plus',
          transform: 'shrink-6 down-6 right-6'
        }
      ]
    }
  },
  'history-event-VEHICLE_INVOICE_SEND': {
    component: IconFontawesome,
    props: {
      type: 'layers',
      icon: 'circle',
      iconMask: 'file-invoice',
      transform: 'down-6 right-6',
      iconChildren: [
        {
          icon: 'envelope',
          transform: 'shrink-6 down-6 right-6'
        }
      ]
    }
  },
  'history-event-CUSTOMER_INVOICE_CREATED': {
    component: IconFontawesome,
    props: {
      type: 'layers',
      icon: 'circle',
      iconMask: 'file-invoice',
      transform: 'down-6 right-6',
      iconChildren: [
        {
          icon: 'plus',
          transform: 'shrink-6 down-6 right-6'
        }
      ]
    }
  },
  'history-event-CUSTOMER_INVOICE_SEND': {
    component: IconFontawesome,
    props: {
      type: 'layers',
      icon: 'circle',
      iconMask: 'file-invoice',
      transform: 'down-6 right-6',
      iconChildren: [
        {
          icon: 'envelope',
          transform: 'shrink-6 down-6 right-6'
        }
      ]
    }
  },
  'history-event-CUSTOMER_INVOICE_UPLOADED': {
    component: IconFontawesome,
    props: {
      type: 'layers',
      icon: 'circle',
      iconMask: 'file-invoice',
      transform: 'down-6 right-6',
      iconChildren: [
        {
          icon: 'arrow-up',
          transform: 'shrink-6 down-6 right-6'
        }
      ]
    }
  },

  // LEAD
  'history-event-LEAD_CHANGE_STATUS': {
    component: IconFontawesome,
    props: {
      type: 'layers',
      icon: 'circle',
      iconMask: 'file',
      transform: 'down-8 right-8',
      iconChildren: [
        {
          icon: 'arrows-rotate',
          transform: 'shrink-8 down-8 right-8'
        }
      ]
    }
  },
  'history-event-LEAD_PAUSE': {
    component: IconFontawesome,
    props: {
      icon: 'pause'
    }
  },
  'history-event-LEAD_CHANGE_BEARBEITUNGSSTATUS': {
    component: IconFontawesome,
    props: {
      type: 'layers',
      icon: 'circle',
      iconMask: 'file',
      transform: 'down-8 right-8',
      iconChildren: [
        {
          icon: 'arrows-rotate',
          transform: 'shrink-8 down-8 right-8'
        }
      ]
    }
  },
  'history-event-LEAD_CHANGE_BEARBEITER': {
    component: IconFontawesome,
    props: {
      icon: 'user-tie'
    }
  },
  'history-event-LEAD_CLOSE': {
    component: IconFontawesome,
    props: {
      type: 'layers',
      icon: 'circle',
      iconMask: 'file',
      transform: 'down-8 right-8',
      iconChildren: [
        {
          icon: 'arrows-rotate',
          transform: 'shrink-8 down-8 right-8'
        }
      ]
    }
  },
  'history-event-LEAD_COMMENT_ADD': {
    component: IconFontawesome,
    props: {
      icon: 'file-signature'
    }
  },
  'history-event-LEAD_REMINDER_SET': {
    component: IconFontawesome,
    props: {
      icon: 'bell'
    }
  },
  'history-event-LEAD_REMINDER_DELETED': {
    component: IconFontawesome,
    props: {
      type: 'layers',
      icon: 'circle',
      iconMask: 'bell',
      transform: 'down-8 right-8',
      iconChildren: [
        {
          icon: 'trash',
          transform: 'shrink-8 down-8 right-8'
        }
      ]
    }
  },
  'history-event-LEAD_REMINDER_REACHED': {
    component: IconFontawesome,
    props: {
      type: 'layers',
      icon: 'circle',
      iconMask: 'bell',
      transform: 'down-8 right-8',
      iconChildren: [
        {
          icon: 'check',
          transform: 'shrink-8 down-8 right-8'
        }
      ]
    }
  },
  'history-event-LEAD_CONTACT_EMAIL_INITIAL': {
    component: IconFontawesome,
    props: {
      type: 'layers',
      icon: 'circle',
      iconMask: 'envelope',
      transform: 'down-8 right-8',
      iconChildren: [
        {
          icon: 'paper-plane',
          transform: 'shrink-8 down-8 right-8'
        }
      ]
    }
  },
  'history-event-LEAD_CONTACT_EMAIL': {
    component: IconFontawesome,
    props: {
      type: 'layers',
      icon: 'circle',
      iconMask: 'envelope',
      transform: 'down-8 right-8',
      iconChildren: [
        {
          icon: 'paper-plane',
          transform: 'shrink-8 down-8 right-8'
        }
      ]
    }
  },
  'history-event-LEAD_CONTACT_EMAIL_EXTERN': {
    component: IconFontawesome,
    props: {
      type: 'layers',
      icon: 'circle',
      iconMask: 'envelope',
      transform: 'down-8 right-8',
      iconChildren: [
        {
          icon: 'paper-plane',
          transform: 'shrink-8 down-8 right-8'
        }
      ]
    }
  },
  'history-event-LEAD_CONTACT_PHONE': {
    component: IconFontawesome,
    props: {
      type: 'layers',
      icon: 'circle',
      iconMask: 'phone',
      transform: 'down-8 right-8',
      iconChildren: [
        {
          icon: 'paper-plane',
          transform: 'shrink-8 down-8 right-8'
        }
      ]
    }
  },
  'history-event-LEAD_CREATE_DATAPROTECTION_PDF': {
    component: IconFontawesome,
    props: {
      type: 'layers',
      icon: 'circle',
      iconMask: 'database',
      transform: 'down-8 right-8',
      iconChildren: [
        {
          icon: 'plus',
          transform: 'shrink-8 down-8 right-8'
        }
      ]
    }
  },
  'history-event-LEAD_CONTACT_EMAIL_DATAPROTECTION': {
    component: IconFontawesome,
    props: {
      type: 'layers',
      icon: 'circle',
      iconMask: 'database',
      transform: 'down-8 right-8',
      iconChildren: [
        {
          icon: 'paper-plane',
          transform: 'shrink-8 down-8 right-8'
        }
      ]
    }
  },
  'history-event-LEAD_CREATE_PRIVACY_POLICY_PDF': {
    component: IconFontawesome,
    props: {
      type: 'layers',
      icon: 'circle',
      iconMask: 'file-contract',
      transform: 'down-8 right-8',
      iconChildren: [
        {
          icon: 'plus',
          transform: 'shrink-8 down-8 right-8'
        }
      ]
    }
  },
  'history-event-LEAD_GENERATE_PRIVACY_POLICY_URL': {
    component: IconFontawesome,
    props: {
      type: 'layers',
      icon: 'circle',
      iconMask: 'file-contract',
      transform: 'down-8 right-8',
      iconChildren: [
        {
          icon: 'link',
          transform: 'shrink-8 down-8 right-8'
        }
      ]
    }
  },
  'history-event-LEAD_CONTACT_EMAIL_PRIVACY_POLICY': {
    component: IconFontawesome,
    props: {
      type: 'layers',
      icon: 'circle',
      iconMask: 'file-contract',
      transform: 'down-8 right-8',
      iconChildren: [
        {
          icon: 'paper-plane',
          transform: 'shrink-8 down-8 right-8'
        }
      ]
    }
  },
  'history-event-LEAD_CONTACT_SIGNED_PRIVACY_POLICY': {
    component: IconFontawesome,
    props: {
      type: 'layers',
      icon: 'circle',
      iconMask: 'file-contract',
      transform: 'down-8 right-8',
      iconChildren: [
        {
          icon: 'pen',
          transform: 'shrink-8 down-8 right-8'
        }
      ]
    }
  },
  'history-event-LEAD_CREATE_TESTDRIVECONTRACT_PDF': {
    component: IconFontawesome,
    props: {
      type: 'layers',
      icon: 'circle',
      iconMask: 'file-contract',
      transform: 'down-8 right-8',
      iconChildren: [
        {
          icon: 'car',
          transform: 'shrink-8 down-8 right-8'
        }
      ]
    }
  },
  'history-event-LEAD_GENERATE_TESTDRIVECONTRACT_URL': {
    component: IconFontawesome,
    props: {
      type: 'layers',
      icon: 'circle',
      iconMask: 'file-contract',
      transform: 'down-8 right-8',
      iconChildren: [
        {
          icon: 'link',
          transform: 'shrink-8 down-8 right-8'
        }
      ]
    }
  },
  'history-event-LEAD_CONTACT_EMAIL_TESTDRIVECONTRACT': {
    component: IconFontawesome,
    props: {
      type: 'layers',
      icon: 'circle',
      iconMask: 'file-contract',
      transform: 'down-8 right-8',
      iconChildren: [
        {
          icon: 'paper-plane',
          transform: 'shrink-8 down-8 right-8'
        }
      ]
    }
  },
  'history-event-LEAD_CONTACT_SIGNED_TESTDRIVECONTRACT': {
    component: IconFontawesome,
    props: {
      type: 'layers',
      icon: 'circle',
      iconMask: 'file-contract',
      transform: 'down-8 right-8',
      iconChildren: [
        {
          icon: 'pen',
          transform: 'shrink-8 down-8 right-8'
        }
      ]
    }
  },
  'history-event-LEAD_CREATE_RENTALDRIVECONTRACT_PDF': {
    component: IconFontawesome,
    props: {
      type: 'layers',
      icon: 'circle',
      iconMask: 'file-contract',
      transform: 'down-8 right-8',
      iconChildren: [
        {
          icon: 'car',
          transform: 'shrink-8 down-8 right-8'
        }
      ]
    }
  },
  'history-event-LEAD_GENERATE_RENTALDRIVECONTRACT_URL': {
    component: IconFontawesome,
    props: {
      type: 'layers',
      icon: 'circle',
      iconMask: 'file-contract',
      transform: 'down-8 right-8',
      iconChildren: [
        {
          icon: 'link',
          transform: 'shrink-8 down-8 right-8'
        }
      ]
    }
  },
  'history-event-LEAD_CONTACT_EMAIL_RENTALDRIVECONTRACT': {
    component: IconFontawesome,
    props: {
      type: 'layers',
      icon: 'circle',
      iconMask: 'file-contract',
      transform: 'down-8 right-8',
      iconChildren: [
        {
          icon: 'paper-plane',
          transform: 'shrink-8 down-8 right-8'
        }
      ]
    }
  },
  'history-event-LEAD_CONTACT_SIGNED_RENTALDRIVECONTRACT': {
    component: IconFontawesome,
    props: {
      type: 'layers',
      icon: 'circle',
      iconMask: 'file-contract',
      transform: 'down-8 right-8',
      iconChildren: [
        {
          icon: 'pen',
          transform: 'shrink-8 down-8 right-8'
        }
      ]
    }
  },
  'history-event-LEAD_CONTACT_EMAIL_EXPOSE': {
    component: IconFontawesome,
    props: {
      type: 'layers',
      icon: 'circle',
      iconMask: 'file-pdf',
      transform: 'down-8 right-8',
      iconChildren: [
        {
          icon: 'paper-plane',
          transform: 'shrink-8 down-8 right-8'
        }
      ]
    }
  },
  'history-event-LEAD_RECEIVED_EMAIL': {
    component: IconFontawesome,
    props: {
      icon: 'envelope'
    }
  },
  'history-event-LEAD_CUSTOMER_ASSIGNED': {
    component: IconFontawesome,
    props: {
      icon: 'user'
    }
  },
  'history-event-LEADFEEDBACK_CREATED': {
    component: IconFontawesome,
    props: {
      type: 'layers',
      icon: 'circle',
      iconMask: 'star',
      transform: 'down-8 right-8',
      iconChildren: [
        {
          icon: 'paper-plane',
          transform: 'shrink-8 down-8 right-8'
        }
      ]
    }
  },
  'history-event-LEADFEEDBACK_SUBMITTED': {
    component: IconFontawesome,
    props: {
      type: 'layers',
      icon: 'circle',
      iconMask: 'star',
      transform: 'down-8 right-8',
      iconChildren: [
        {
          icon: 'check',
          transform: 'shrink-8 down-8 right-8'
        }
      ]
    }
  },
  'history-event-LEADFEEDBACK_DELETED': {
    component: IconFontawesome,
    props: {
      type: 'layers',
      icon: 'circle',
      iconMask: 'star',
      transform: 'down-8 right-8',
      iconChildren: [
        {
          icon: 'trash',
          transform: 'shrink-8 down-8 right-8'
        }
      ]
    }
  },
  'history-event-LEAD_ESCALATION_MAIL': {
    component: IconFontawesome,
    props: {
      icon: 'bell'
    }
  },

  // STORAGE
  'history-event-STORAGE_TIRE_CREATED': {
    component: IconFontawesome,
    props: {
      type: 'layers',
      icon: 'circle',
      iconMask: 'cookie',
      transform: 'down-6 right-6',
      iconChildren: [
        {
          icon: 'plus',
          transform: 'shrink-6 down-6 right-6'
        }
      ]
    }
  },
  'history-event-STORAGE_TIRE_UPDATED': {
    component: IconFontawesome,
    props: {
      type: 'layers',
      icon: 'circle',
      iconMask: 'cookie',
      transform: 'down-6 right-6',
      iconChildren: [
        {
          icon: 'arrows-rotate',
          transform: 'shrink-6 down-6 right-6'
        }
      ]
    }
  },
  'history-event-STORAGE_TIRE_DELETED': {
    component: IconFontawesome,
    props: {
      type: 'layers',
      icon: 'circle',
      iconMask: 'cookie',
      transform: 'down-6 right-6',
      iconChildren: [
        {
          icon: 'trash',
          transform: 'shrink-6 down-6 right-6'
        }
      ]
    }
  },
  'history-event-STORAGE_GENERATE_TIRESIN_PDF': {
    component: IconFontawesome,
    props: {
      icon: 'file-pdf'
    }
  },
  'history-event-STORAGE_GENERATE_TIRESOUT_PDF': {
    component: IconFontawesome,
    props: {
      icon: 'file-pdf'
    }
  },

  // LEAD / CUSTOMER: Selfdisclosure
  'history-event-SELFDISCLOSURE_CREATED': {
    component: IconFontawesome,
    props: {
      type: 'layers',
      icon: 'circle',
      iconMask: 'id-card',
      transform: 'down-8 right-8',
      iconChildren: [
        {
          icon: 'paper-plane',
          transform: 'shrink-8 down-8 right-8'
        }
      ]
    }
  },
  'history-event-SELFDISCLOSURE_SUBMITTED': {
    component: IconFontawesome,
    props: {
      type: 'layers',
      icon: 'circle',
      iconMask: 'id-card',
      transform: 'down-8 right-8',
      iconChildren: [
        {
          icon: 'check',
          transform: 'shrink-8 down-8 right-8'
        }
      ]
    }
  },
  'history-event-SELFDISCLOSURE_DELETED': {
    component: IconFontawesome,
    props: {
      type: 'layers',
      icon: 'circle',
      iconMask: 'id-card',
      transform: 'down-8 right-8',
      iconChildren: [
        {
          icon: 'trash',
          transform: 'shrink-8 down-8 right-8'
        }
      ]
    }
  },

  // LEAD / CUSTOMER: Selfdisclosure
  'history-event-ISSUE_CLOSED': {
    component: IconFontawesome,
    props: {
      icon: 'check'
    }
  },
  'history-event-ISSUE_OPEN': {
    component: IconFontawesome,
    props: {
      icon: 'check'
    }
  },
  'history-event-ISSUE_RECEIVED_EMAIL': {
    component: IconFontawesome,
    props: {
      icon: 'envelope'
    }
  },
  'history-event-NEW_ISSUE_RECEIVED_EMAIL': {
    component: IconFontawesome,
    props: {
      icon: 'envelope'
    }
  },
  'history-event-ISSUE_CONTACT_EMAIL': {
    component: IconFontawesome,
    props: {
      type: 'layers',
      icon: 'circle',
      iconMask: 'envelope',
      transform: 'down-8 right-8',
      iconChildren: [
        {
          icon: 'paper-plane',
          transform: 'shrink-8 down-8 right-8'
        }
      ]
    }
  },

  // User
  'history-event-USER_LOGIN': {
    component: IconFontawesome,
    props: {
      icon: 'lock'
    }
  },

  // Sicherungsschein
  'history-event-NEW_LESSOR_CERTIFICATE_OUT': {
    component: IconFontawesome,
    props: {
      icon: 'paper-plane'
    }
  },
  'history-event-NEW_LESSOR_CERTIFICATE_IN': {
    component: IconFontawesome,
    props: {
      icon: 'file-circle-check'
    }
  },
  'history-event-NEW_LESSOR_CERTIFICATE_INFO': {
    component: IconFontawesome,
    props: {
      icon: 'paper-plane'
    }
  },

  // freshBIKE
  'history-event-FRESHBIKE_BIKE_CANCELED': {
    component: IconFontawesome,
    props: {
      icon: 'trash'
    }
  },
  'history-event-FRESHBIKE_BIKE_SET_STATUS_OK': {
    component: IconFontawesome,
    props: {
      icon: 'check'
    }
  },

  // Workshoprepairreports
  'history-event-WORKSHOPREPAIRREPORT_ITEM_CREATED': {
    component: IconFontawesome,
    props: {
      type: 'layers',
      icon: 'circle',
      iconMask: 'screwdriver-wrench',
      transform: 'down-6 right-6',
      iconChildren: [
        {
          icon: 'plus',
          transform: 'shrink-6 down-6 right-6'
        }
      ]
    }
  },
  'history-event-WORKSHOPREPAIRREPORT_ITEM_UPDATED': {
    component: IconFontawesome,
    props: {
      type: 'layers',
      icon: 'circle',
      iconMask: 'screwdriver-wrench',
      transform: 'down-6 right-6',
      iconChildren: [
        {
          icon: 'arrows-rotate',
          transform: 'shrink-6 down-6 right-6'
        }
      ]
    }
  },
  'history-event-WORKSHOPREPAIRREPORT_ITEM_DELETED': {
    component: IconFontawesome,
    props: {
      type: 'layers',
      icon: 'circle',
      iconMask: 'screwdriver-wrench',
      transform: 'down-6 right-6',
      iconChildren: [
        {
          icon: 'trash',
          transform: 'shrink-6 down-6 right-6'
        }
      ]
    }
  }
}
