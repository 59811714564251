import _ from 'lodash'
import Vue from 'vue'
import Router from 'vue-router'
import store from '@/store/index.js'

// Error Pages
import Error401 from '@/views/errors/401.vue'
import Error404 from '@/views/errors/404.vue'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    /**
     * App
     */
    {
      path: '/',
      redirect: { name: 'dashboard' }
    },

    // Login
    {
      path: '/login',
      name: 'login',
      component: () => import('@/views/auth/Login.vue'),
      props: (route) => ({
        redirect: route.query.redirect || { name: 'dashboard' }
      }),
      meta: {
        requiresAuth: false,
        layout: {
          fillHeight: true
        },
        title: 'Login'
      }
    },
    {
      path: '/register',
      name: 'register',
      component: () => import('@/views/auth/Register.vue'),
      meta: {
        requiresAuth: false,
        layout: {
          fillHeight: true
        },
        title: 'Registrierung'
      }
    },
    {
      path: '/reset-password',
      name: 'reset-password',
      component: () => import('@/views/auth/ResetPassword.vue'),
      props: (route) => ({
        token: route.query.token
      }),
      meta: {
        requiresAuth: false,
        layout: {
          fillHeight: true
        },
        title: 'Passwort zurücksetzen'
      }
    },
    {
      path: '/verify-email',
      name: 'verify-email',
      component: () => import('@/views/auth/VerifyEmail.vue'),
      props: (route) => ({
        token: route.query.token
      }),
      meta: {
        requiresAuth: false,
        layout: {
          fillHeight: true
        },
        title: 'E-Mail Bestätigung'
      }
    },

    // Userfeedback
    {
      path: '/userfeedback/selfdisclosure',
      redirect: { name: 'feedback' }
    },
    {
      path: '/feedback',
      name: 'feedback',
      component: () => import('@/views/feedback/Feedback.vue'),
      props: (route) => ({
        token: route.query.token
      }),
      meta: {
        requiresAuth: false
      }
    },

    // Dashboard
    {
      path: '/dashboard',
      name: 'dashboard',
      component: () => import('@/views/dashboard/Dashboard.vue'),
      meta: {
        requiresAuth: true,
        requiresPermissions: ['dashboard.show'],
        title: 'Dashboard',
        knowledgeBaseUrl: {
          default: 'https://freshgroup.atlassian.net/l/c/fQ7huahj',
          freshforfinance: 'https://freshgroup.atlassian.net/l/c/fQ7huahj',
          'fahrzeugmarkt.online':
            'https://freshgroup.atlassian.net/l/c/wk02H8GL'
        }
      }
    },

    // Globalsearch
    {
      path: '/search',
      name: 'globalsearch',
      component: () => import('@/views/globalsearch/Globalsearch.vue'),
      props: (route) => ({ search: route.query.search }),
      meta: {
        requiresAuth: true,
        requiresPermissions: ['globalsearch'],
        title: 'Suche'
      }
    },

    /**
     * Antrag START
     */

    // Antrag
    {
      path: '/antrag',
      name: 'antrag',
      component: () => import('@/views/antrag/Overview.vue'),
      meta: {
        requiresAuth: true,
        requiresPermissions: [
          'antragsoverview.show',
          'freshcarflat.show',
          'builder.assignment-coverage.show',
          'freshcarsharing.show',
          'freshbike.show',
          'freshmofa.show',
          'freshscooter.show'
        ],
        title: 'Antrag',
        knowledgeBaseUrl: 'https://freshgroup.atlassian.net/l/c/HYS1nGVA'
      }
      // We don't need this anymore, because we do have a route for the "overview". The user doesn't expect to be redirected.
      // // We will redirect directly to the "antrag" if he only has the permission for one antrag
      // beforeEnter: (to, from, next) => {
      //   const hasPermission = store.getters['auth/hasPermission']
      //   const allowedPages = []

      //   // freshCARflat and freshCARfleet
      //   if (hasPermission('freshcarflat.show')) {
      //     allowedPages.push('antrag-freshcarflat-rangliste')
      //   }
      //   if (hasPermission('builder.assignment-coverage.show')) {
      //     allowedPages.push('builder-assignment-coverages')
      //   }
      //   // freshCARsharing
      //   if (hasPermission('freshcarsharing.show')) {
      //     allowedPages.push('antrag-freshcarsharing-rangliste')
      //   }
      //   // freshBIKE
      //   if (hasPermission('freshbike.show')) {
      //     allowedPages.push('freshbike')
      //   }
      //   // freshMOFA
      //   if (hasPermission('freshmofa.show')) {
      //     allowedPages.push('antrag-freshmofa-rangliste')
      //   }
      //   // freshSCOOTER
      //   if (hasPermission('freshscooter.show')) {
      //     allowedPages.push('antrag-freshscooter-rangliste')
      //   }

      //   if (hasPermission('antragsoverview.show') || allowedPages.length > 1) {
      //     // if the user is allowed to view the whole overview or if he is allowed to access more than one antragsprozess, we will show him the overview
      //     next()
      //   } else if (allowedPages.length === 1) {
      //     // redirect directly to the single antragsprozess
      //     next({ name: allowedPages[0] })
      //   } else {
      //     // 401 Unauthorized
      //     next({ name: 'error-401' })
      //   }
      // }
    },

    /* freshcarflat */
    {
      path: '/antrag/freshcarflat',
      name: 'freshcarflat',
      redirect: { name: 'antrag-freshcarflat-vorgaenge' }
    },
    {
      path: '/antrag/freshcarfleet',
      name: 'freshcarfleet',
      redirect: { name: 'antrag-freshcarflat-vorgaenge' }
    },
    {
      path: '/antrag/freshcarflat/userfeedback/geplante-aufhebung',
      name: 'antrag-freshcarflat-userfeedback-geplanteaufhebung',
      component: () =>
        import('@/views/antrag/freshcarflat/UserfeedbackGeplanteAufhebung.vue'),
      props: (route) => {
        let verlaengerung = null

        // route.query.verlaengerung is not null or undefined
        if (route.query.verlaengerung != null) {
          let verlaengerungLC = String(route.query.verlaengerung).toLowerCase()

          if (verlaengerungLC === 'true') {
            verlaengerung = true
          } else if (verlaengerungLC === 'false') {
            verlaengerung = false
          } else {
            // if the parameter is not 'true' or 'false', we will ignore it.
            verlaengerung = null
          }
        }

        return {
          token: route.query.token,
          verlaengerung
        }
      },
      meta: {
        requiresAuth: false
      }
    },
    {
      path: '/antrag/freshcarflat/rangliste',
      name: 'antrag-freshcarflat-rangliste',
      components: {
        default: () => import('@/views/antrag/freshcarflat/Rangliste.vue'),
        appBar: () => import('@/views/antrag/freshcarflat/AppBar.vue'),
        sideSheet: () =>
          import('@/views/antrag/freshcarflat/RanglisteSideSheet.vue')
      },
      meta: {
        requiresAuth: true,
        requiresPermissions: ['freshcarflat.show'],
        title: 'Rangliste',
        knowledgeBaseUrl: 'https://freshgroup.atlassian.net/l/c/msq6zxNC'
      }
    },
    {
      path: '/antrag/freshcarflat/vorgaenge',
      name: 'antrag-freshcarflat-vorgaenge',
      components: {
        default: () => import('@/views/antrag/freshcarflat/Vorgaenge.vue'),
        appBar: () => import('@/views/antrag/freshcarflat/AppBar.vue'),
        sideSheet: () =>
          import('@/views/antrag/freshcarflat/VorgaengeSideSheet.vue')
      },
      meta: {
        requiresAuth: true,
        requiresPermissions: ['freshcarflat.show'],
        title: 'Vorgänge',
        knowledgeBaseUrl: 'https://freshgroup.atlassian.net/l/c/msq6zxNC'
      }
    },
    {
      path: '/antrag/freshcarflat/antrag/:id?',
      name: 'antrag-freshcarflat-antrag',
      components: {
        default: () => import('@/views/antrag/freshcarflat/Antrag.vue'),
        appBar: () => import('@/views/antrag/freshcarflat/AppBar.vue')
      },
      props: {
        default: (route) => ({
          id: isNaN(route.params.id) ? null : parseInt(route.params.id, 10),
          editFahrzeugId: route.params.editFahrzeugId,
          verlaengernFahrzeugId: route.params.verlaengernFahrzeugId
        })
      },
      meta: {
        requiresAuth: true,
        requiresPermissions: ['freshcarflat.show'],
        title: 'Vorgang',
        knowledgeBaseUrl: 'https://freshgroup.atlassian.net/l/c/UVPb69sf'
      }
    },
    {
      path: '/antrag/freshcarflat/antrag/:id/evb',
      name: 'antrag-freshcarflat-evb',
      component: () => import('@/views/antrag/freshcarflat/Evb.vue'),
      props: (route) => ({
        id: isNaN(route.params.id) ? null : parseInt(route.params.id, 10),
        fahrzeugId: isNaN(route.query.fahrzeugId)
          ? null
          : parseInt(route.query.fahrzeugId, 10),
        to: {
          name: 'antrag-freshcarflat-antrag',
          params: { id: route.params.id }
        }
      }),
      meta: {
        requiresAuth: true,
        requiresPermissions: ['freshcarflat.show'],
        title: 'Vorgang',
        knowledgeBaseUrl: 'https://freshgroup.atlassian.net/l/c/msq6zxNC'
      }
    },

    // freshCARsharing
    {
      path: '/antrag/freshcarsharing',
      name: 'freshcarsharing',
      redirect: { name: 'freshcarsharing-applications' }
    },

    {
      path: '/antrag/freshcarsharing/applications',
      name: 'freshcarsharing-applications',
      components: {
        default: () =>
          import(
            /* webpackChunkName: "freshCARsharing" */ '@/views/antrag/freshcarsharing/Applications.vue'
          ),
        appBar: () =>
          import(
            /* webpackChunkName: "freshCARsharing" */ '@/views/antrag/freshcarsharing/AppBar.vue'
          )
      },
      meta: {
        requiresAuth: true,
        requiresPermissions: ['freshcarsharing.show'],
        title: 'Vorgänge',
        knowledgeBaseUrl: 'https://freshgroup.atlassian.net/l/c/VLNqu11B'
      }
    },

    {
      path: '/antrag/freshcarsharing/application/:id?',
      name: 'freshcarsharing-application',
      components: {
        default: () =>
          import(
            /* webpackChunkName: "freshCARsharing" */ '@/views/antrag/freshcarsharing/Application.vue'
          ),
        appBar: () =>
          import(
            /* webpackChunkName: "freshCARsharing" */ '@/views/antrag/freshcarsharing/AppBar.vue'
          )
      },
      props: {
        default: true
      },
      meta: {
        requiresAuth: true,
        requiresPermissions: ['freshcarsharing.show'],
        title: 'Antrag',
        knowledgeBaseUrl: 'https://freshgroup.atlassian.net/l/c/VLNqu11B'
      }
    },

    {
      path: '/antrag/freshcarsharing/car/:id?',
      name: 'freshcarsharing-car',
      components: {
        default: () =>
          import(
            /* webpackChunkName: "freshCARsharing" */ '@/views/antrag/freshcarsharing/Car.vue'
          ),
        appBar: () =>
          import(
            /* webpackChunkName: "freshCARsharing" */ '@/views/antrag/freshcarsharing/AppBar.vue'
          )
      },
      props: {
        default: true
      },
      meta: {
        requiresAuth: true,
        requiresPermissions: ['freshcarsharing.show'],
        title: 'Fahrzeug',
        knowledgeBaseUrl: 'https://freshgroup.atlassian.net/l/c/VLNqu11B'
      }
    },

    // freshBAUimmo
    {
      path: '/antrag/freshbauimmo',
      name: 'builder',
      redirect: { name: 'builder-assignment-coverages' }
    },

    {
      path: '/antrag/freshbauimmo/vorgaenge',
      name: 'builder-assignment-coverages',
      components: {
        default: () =>
          import(
            /* webpackChunkName: "freshBAUimmo" */ '@/views/antrag/freshbauimmo/AssignmentCoverages.vue'
          ),
        appBar: () =>
          import(
            /* webpackChunkName: "freshBAUimmo" */ '@/views/antrag/freshbauimmo/AppBar.vue'
          )
      },
      meta: {
        requiresAuth: true,
        requiresPermissions: ['builder.assignment-coverage.show'],
        title: 'Vorgänge',
        knowledgeBaseUrl: 'https://freshgroup.atlassian.net/l/c/cnxXfh01'
      }
    },

    {
      path: '/antrag/freshbauimmo/antrag/:id?',
      name: 'builder-assignment-coverage',
      components: {
        default: () =>
          import(
            /* webpackChunkName: "freshBAUimmo" */ '@/views/antrag/freshbauimmo/AssignmentCoverage.vue'
          ),
        appBar: () =>
          import(
            /* webpackChunkName: "freshBAUimmo" */ '@/views/antrag/freshbauimmo/AppBar.vue'
          )
      },
      props: {
        default: true
      },
      meta: {
        requiresAuth: true,
        requiresPermissions: ['builder.assignment-coverage.show'],
        title: 'Antrag',
        knowledgeBaseUrl: 'https://freshgroup.atlassian.net/l/c/cnxXfh01'
      }
    },

    // freshBIKE
    {
      path: '/antrag/freshbike',
      name: 'freshbike',
      redirect: { name: 'freshbike-bikes' }
    },

    {
      path: '/antrag/freshbike/statistics',
      name: 'freshbike-statistics',
      components: {
        default: () =>
          import(
            /* webpackChunkName: "freshBIKE" */ '@/views/antrag/freshbike/Statistics.vue'
          ),
        appBar: () =>
          import(
            /* webpackChunkName: "freshBIKE" */ '@/views/antrag/freshbike/AppBar.vue'
          )
      },
      meta: {
        requiresAuth: true,
        requiresPermissions: ['freshbike.show'],
        title: 'Übersicht',
        knowledgeBaseUrl: 'https://freshgroup.atlassian.net/l/c/LjQcYE1A'
      }
    },

    {
      path: '/antrag/freshbike/applications',
      name: 'freshbike-applications',
      components: {
        default: () =>
          import(
            /* webpackChunkName: "freshBIKE" */ '@/views/antrag/freshbike/Applications.vue'
          ),
        appBar: () =>
          import(
            /* webpackChunkName: "freshBIKE" */ '@/views/antrag/freshbike/AppBar.vue'
          )
      },
      meta: {
        requiresAuth: true,
        requiresPermissions: ['freshbike.show'],
        title: 'Vorgänge',
        knowledgeBaseUrl: 'https://freshgroup.atlassian.net/l/c/LjQcYE1A'
      }
    },

    {
      path: '/antrag/freshbike/application/:id?',
      name: 'freshbike-application',
      components: {
        default: () =>
          import(
            /* webpackChunkName: "freshBIKE" */ '@/views/antrag/freshbike/Application.vue'
          ),
        appBar: () =>
          import(
            /* webpackChunkName: "freshBIKE" */ '@/views/antrag/freshbike/AppBar.vue'
          )
      },
      props: {
        default: true
      },
      meta: {
        requiresAuth: true,
        requiresPermissions: ['freshbike.show'],
        title: 'Antrag',
        knowledgeBaseUrl: 'https://freshgroup.atlassian.net/l/c/LjQcYE1A'
      }
    },

    {
      path: '/antrag/freshbike/bikes',
      name: 'freshbike-bikes',
      components: {
        default: () =>
          import(
            /* webpackChunkName: "freshBIKE" */ '@/views/antrag/freshbike/Bikes.vue'
          ),
        appBar: () =>
          import(
            /* webpackChunkName: "freshBIKE" */ '@/views/antrag/freshbike/AppBar.vue'
          )
      },
      meta: {
        requiresAuth: true,
        requiresPermissions: ['freshbike.show'],
        title: 'Fahrräder',
        knowledgeBaseUrl: 'https://freshgroup.atlassian.net/l/c/LjQcYE1A'
      }
    },

    {
      path: '/antrag/freshbike/bike/:id?',
      name: 'freshbike-bike',
      components: {
        default: () =>
          import(
            /* webpackChunkName: "freshBIKE" */ '@/views/antrag/freshbike/Bike.vue'
          ),
        appBar: () =>
          import(
            /* webpackChunkName: "freshBIKE" */ '@/views/antrag/freshbike/AppBar.vue'
          )
      },
      props: {
        default: true
      },
      meta: {
        requiresAuth: true,
        requiresPermissions: ['freshbike.show'],
        title: 'Fahrrad',
        knowledgeBaseUrl: 'https://freshgroup.atlassian.net/l/c/LjQcYE1A'
      }
    },

    /**
     * Antrag END
     */

    /**
     * Schadenmeldung START
     */

    // Schadenmeldung
    {
      path: '/schadenmeldung',
      name: 'schadenmeldung',
      component: () => import('@/views/schadenmeldung/Overview.vue'),
      meta: {
        requiresAuth: true,
        requiresPermissions: ['claimoverview.show', 'cardamage.show'],
        title: 'Schadenmeldung',
        knowledgeBaseUrl: 'https://freshgroup.atlassian.net/l/cp/i2NqVfyE'
      }
    },

    // freshCARsharing
    {
      path: '/schadenmeldung/cardamage/list',
      name: 'schadenmeldung-cardamage-list',
      components: {
        default: () =>
          import(
            /* webpackChunkName: "schadenmeldung" */ '@/views/schadenmeldung/freshcarsharing/Damages.vue'
          ),
        appBar: () =>
          import(
            /* webpackChunkName: "schadenmeldung" */ '@/views/schadenmeldung/freshcarsharing/DamageAppBar.vue'
          ),
        sideSheet: () =>
          import(
            /* webpackChunkName: "schadenmeldung" */ '@/views/schadenmeldung/freshcarsharing/DamagesSideSheet.vue'
          )
      },
      meta: {
        requiresAuth: true,
        requiresPermissions: ['cardamage.list.all', 'cardamage.list.own'],
        title: 'Schadenmeldung - Übersicht',
        knowledgeBaseUrl: 'https://freshgroup.atlassian.net/l/c/DM6EWyBe'
      }
    },
    {
      path: '/schadenmeldung/cardamage/create/:id?',
      name: 'schadenmeldung-cardamage-create',
      components: {
        default: () =>
          import(
            /* webpackChunkName: "schadenmeldung" */ '@/views/schadenmeldung/freshcarsharing/DamageCreate.vue'
          ),
        appBar: () =>
          import(
            /* webpackChunkName: "schadenmeldung" */ '@/views/schadenmeldung/freshcarsharing/DamageAppBar.vue'
          )
      },
      props: {
        default: true
      },
      meta: {
        requiresAuth: true,
        requiresPermissions: ['cardamage.create'],
        title: 'Schaden melden',
        knowledgeBaseUrl: 'https://freshgroup.atlassian.net/l/c/DM6EWyBe'
      }
    },
    {
      path: '/schadenmeldung/cardamage/:id?',
      name: 'schadenmeldung-cardamage-details',
      components: {
        default: () =>
          import(
            /* webpackChunkName: "schadenmeldung" */ '@/views/schadenmeldung/freshcarsharing/DamageDetails.vue'
          ),
        appBar: () =>
          import(
            /* webpackChunkName: "schadenmeldung" */ '@/views/schadenmeldung/freshcarsharing/DamageAppBar.vue'
          )
      },
      props: {
        default: true
      },
      meta: {
        requiresAuth: true,
        requiresPermissions: ['cardamage.list.all', 'cardamage.list.own'],
        title: 'Schadenmeldung',
        knowledgeBaseUrl: 'https://freshgroup.atlassian.net/l/c/DM6EWyBe'
      }
    },
    {
      path: '/schadenmeldung/claimstatements',
      name: 'schadenmeldung-claimstatements',
      components: {
        default: () =>
          import(
            /* webpackChunkName: "schadenmeldung-claimstatements" */ '@/views/schadenmeldung/freshcarsharing/Claimstatements.vue'
          ),
        appBar: () =>
          import(
            /* webpackChunkName: "schadenmeldung-claimstatements" */ '@/views/schadenmeldung/freshcarsharing/DamageAppBar.vue'
          ),
        sideSheet: () =>
          import(
            /* webpackChunkName: "schadenmeldung-claimstatements" */ '@/views/feedback/FeedbacksSideSheet.vue'
          )
      },
      meta: {
        requiresAuth: true,
        requiresPermissions: ['cardamage.list.all', 'cardamage.list.own'],
        title: 'Stellungnahmen',
        knowledgeBaseUrl: 'https://freshgroup.atlassian.net/l/c/DM6EWyBe'
      }
    },

    // freshBIKE
    {
      path: '/schadenmeldung/freshbike/claims',
      name: 'schadenmeldung-freshbike-claims',
      components: {
        default: () =>
          import(
            /* webpackChunkName: "schadenmeldung-freshbike" */ '@/views/schadenmeldung/freshbike/Claims.vue'
          ),
        appBar: () =>
          import(
            /* webpackChunkName: "schadenmeldung-freshbike" */ '@/views/schadenmeldung/freshbike/ClaimsAppBar.vue'
          ),
        sideSheet: () =>
          import(
            /* webpackChunkName: "schadenmeldung-freshbike" */ '@/views/schadenmeldung/freshbike/ClaimsSideSheet.vue'
          )
      },
      meta: {
        requiresAuth: true,
        requiresPermissions: [
          'claims.freshbike.list.all',
          'claims.freshbike.list.own'
        ],
        title: 'Schadenmeldung - Übersicht',
        knowledgeBaseUrl: 'https://freshgroup.atlassian.net/l/cp/kBgv6hCa'
      }
    },
    {
      path: '/schadenmeldung/freshbike/claim/:id?',
      name: 'schadenmeldung-freshbike-claim',
      components: {
        default: () =>
          import(
            /* webpackChunkName: "schadenmeldung-freshbike" */ '@/views/schadenmeldung/freshbike/Claim.vue'
          ),
        appBar: () =>
          import(
            /* webpackChunkName: "schadenmeldung-freshbike" */ '@/views/schadenmeldung/freshbike/ClaimsAppBar.vue'
          )
      },
      props: {
        default: true
      },
      meta: {
        requiresAuth: true,
        requiresPermissions: ['claims.freshbike.show'],
        title: 'Schadenmeldung',
        knowledgeBaseUrl: 'https://freshgroup.atlassian.net/l/cp/kBgv6hCa'
      }
    },

    /**
     * Schadenmeldung END
     */

    /**
     * Bestand START
     */
    {
      path: '/bestand/import',
      name: 'bestand-import',
      components: {
        default: () =>
          import(/* webpackChunkName: "bestand" */ '@/views/bestand/Import.vue')
      },
      meta: {
        requiresAuth: true,
        requiresPermissions: ['bestand.import'],
        requiresClients: ['freshforfinance'],
        title: 'Bestandsimport',
        knowledgeBaseUrl: 'https://freshgroup.atlassian.net/l/c/su5zpqeY'
      }
    },
    {
      path: '/bestand/partner',
      name: 'bestand-partners',
      components: {
        default: () =>
          import(
            /* webpackChunkName: "bestand-partner" */ '@/views/bestand/BestandPartnerOverview.vue'
          ),
        appBar: () =>
          import(
            /* webpackChunkName: "bestand-partner" */ '@/views/bestand/AppBar.vue'
          ),
        sideSheet: () =>
          import(
            /* webpackChunkName: "bestand-partner" */ '@/views/bestand/BestandPartnerSideSheet.vue'
          )
      },
      meta: {
        requiresAuth: true,
        requiresPermissions: ['bestand.partner.show'],
        requiresClients: ['freshforfinance'],
        title: 'Bestand - Partner',
        knowledgeBaseUrl: 'https://freshgroup.atlassian.net/l/c/T0XD0S0Q'
      }
    },
    {
      path: '/bestand/partner/:id?',
      name: 'bestand-partner',
      components: {
        default: () =>
          import(
            /* webpackChunkName: "bestand-partner" */ '@/views/bestand/BestandPartner.vue'
          ),
        appBar: () =>
          import(
            /* webpackChunkName: "bestand-partner" */ '@/views/bestand/AppBar.vue'
          )
      },
      props: {
        default: true
      },
      meta: {
        requiresAuth: true,
        requiresPermissions: ['bestand.partner.show'],
        requiresClients: ['freshforfinance'],
        title: 'Bestand - Partner',
        knowledgeBaseUrl: 'https://freshgroup.atlassian.net/l/c/T0XD0S0Q'
      }
    },
    {
      path: '/bestand/vertraege',
      name: 'bestand-vertraege',
      components: {
        default: () =>
          import(
            /* webpackChunkName: "bestand-vertraege" */ '@/views/bestand/BestandVertraegeOverview.vue'
          ),
        appBar: () =>
          import(
            /* webpackChunkName: "bestand-vertraege" */ '@/views/bestand/AppBar.vue'
          ),
        sideSheet: () =>
          import(
            /* webpackChunkName: "bestand-vertraege" */ '@/views/bestand/BestandVertraegeSideSheet.vue'
          )
      },
      meta: {
        requiresAuth: true,
        requiresPermissions: ['bestand.vertraege.show'],
        requiresClients: ['freshforfinance'],
        title: 'Bestand - Verträge',
        knowledgeBaseUrl: 'https://freshgroup.atlassian.net/l/c/T0XD0S0Q'
      }
    },
    {
      path: '/bestand/vertraege/:id?',
      name: 'bestand-vertrag',
      components: {
        default: () =>
          import(
            /* webpackChunkName: "bestand-vertraege" */ '@/views/bestand/BestandVertrag.vue'
          ),
        appBar: () =>
          import(
            /* webpackChunkName: "bestand-vertraege" */ '@/views/bestand/AppBar.vue'
          )
      },
      props: {
        default: true
      },
      meta: {
        requiresAuth: true,
        requiresPermissions: ['bestand.vertraege.show'],
        requiresClients: ['freshforfinance'],
        title: 'Bestand - Vertrag',
        knowledgeBaseUrl: 'https://freshgroup.atlassian.net/l/c/T0XD0S0Q'
      }
    },
    {
      path: '/bestand/schaeden',
      name: 'bestand-schaeden',
      components: {
        default: () =>
          import(
            /* webpackChunkName: "bestand-schaeden" */ '@/views/bestand/BestandSchaedenOverview.vue'
          ),
        appBar: () =>
          import(
            /* webpackChunkName: "bestand-schaeden" */ '@/views/bestand/AppBar.vue'
          ),
        sideSheet: () =>
          import(
            /* webpackChunkName: "bestand-schaeden" */ '@/views/bestand/BestandSchaedenSideSheet.vue'
          )
      },
      meta: {
        requiresAuth: true,
        requiresPermissions: [
          'bestand.schaeden.show',
          'carsharingmanagement.audit'
        ],
        requiresClients: ['freshforfinance'],
        title: 'Bestand - Schäden',
        knowledgeBaseUrl: 'https://freshgroup.atlassian.net/l/c/T0XD0S0Q'
      }
    },
    {
      path: '/bestand/schaeden/:id?',
      name: 'bestand-schaden',
      components: {
        default: () =>
          import(
            /* webpackChunkName: "bestand-schaeden" */ '@/views/bestand/BestandSchaden.vue'
          ),
        appBar: () =>
          import(
            /* webpackChunkName: "bestand-schaeden" */ '@/views/bestand/AppBar.vue'
          )
      },
      props: {
        default: true
      },
      meta: {
        requiresAuth: true,
        requiresPermissions: ['bestand.schaeden.show'],
        requiresClients: ['freshforfinance'],
        title: 'Bestand - Schaden',
        knowledgeBaseUrl: 'https://freshgroup.atlassian.net/l/c/T0XD0S0Q'
      }
    },
    /**
     * Bestand END
     */

    // Fahrzeugmarkt
    {
      path: '/fahrzeugmarkt/startpage',
      name: 'fahrzeugmarkt-startpage',
      components: {
        default: () => import('@/views/fahrzeugmarkt/Startpage.vue')
      },
      meta: {
        requiresAuth: true,
        requiresPermissions: [
          'fahrzeugmarkt.rangliste.show',
          'fahrzeugmarkt.fahrzeuge.show',
          'fahrzeugmarkt.angebote.show',
          'fahrzeugmarkt.leads.show'
        ],
        requiresClients: ['freshforfinance'],
        knowledgeBaseUrl: 'https://freshgroup.atlassian.net/l/c/2FRrWfcC'
      }
    },

    // Calendar
    {
      path: '/calendar',
      name: 'calendar',
      component: () =>
        import(
          /* webpackChunkName: "calendar" */ '@/views/calendar/Calendar.vue'
        ),
      props: true,
      meta: {
        requiresAuth: true,
        requiresPermissions: ['fahrzeugmarkt.calendar.show'],
        // requiresClients: ['fahrzeugmarkt.online'],
        title: 'Kalender',
        knowledgeBaseUrl: 'https://freshgroup.atlassian.net/l/cp/B11c5UjX'
      }
    },

    // Forms / Formrequests
    {
      path: '/forms/formrequests',
      name: 'formrequests',
      components: {
        default: () =>
          import(
            /* webpackChunkName: "forms-formrequests" */ '@/views/forms/Formrequests.vue'
          ),
        appBar: () =>
          import(
            /* webpackChunkName: "forms-formrequests" */ '@/views/forms/AppBar.vue'
          ),
        sideSheet: () =>
          import(
            /* webpackChunkName: "forms-formrequests" */ '@/views/feedback/FeedbacksSideSheet.vue'
          )
      },
      meta: {
        requiresAuth: true,
        requiresPermissions: ['fahrzeugmarkt.customers.show'],
        // requiresClients: ['fahrzeugmarkt.online'],
        title: 'Formulare',
        knowledgeBaseUrl: 'https://freshgroup.atlassian.net/l/cp/xCFx2ynH'
      }
    },
    {
      path: '/forms/selfdisclosures',
      name: 'selfdisclosures',
      components: {
        default: () =>
          import(
            /* webpackChunkName: "forms-selfdisclosures" */ '@/views/fahrzeugmarkt/Selfdisclosures.vue'
          ),
        appBar: () =>
          import(
            /* webpackChunkName: "forms-selfdisclosures" */ '@/views/forms/AppBar.vue'
          ),
        sideSheet: () =>
          import(
            /* webpackChunkName: "forms-selfdisclosures" */ '@/views/feedback/FeedbacksSideSheet.vue'
          )
      },
      meta: {
        requiresAuth: true,
        requiresPermissions: ['fahrzeugmarkt.customers.show'],
        // requiresClients: ['fahrzeugmarkt.online'],
        title: 'Selbstauskünfte',
        knowledgeBaseUrl: 'https://freshgroup.atlassian.net/l/c/02YsEZ0R'
      }
    },
    {
      path: '/forms/dataprotections',
      name: 'dataprotections',
      components: {
        default: () =>
          import(
            /* webpackChunkName: "forms-dataprotections" */ '@/views/fahrzeugmarkt/Dataprotections.vue'
          ),
        appBar: () =>
          import(
            /* webpackChunkName: "forms-dataprotections" */ '@/views/forms/AppBar.vue'
          ),
        sideSheet: () =>
          import(
            /* webpackChunkName: "forms-dataprotections" */ '@/views/fahrzeugmarkt/DataprotectionsSideSheet.vue'
          )
      },
      meta: {
        requiresAuth: true,
        requiresPermissions: ['fahrzeugmarkt.customers.show'],
        // requiresClients: ['fahrzeugmarkt.online'],
        title: 'Datenschutz',
        knowledgeBaseUrl: 'https://freshgroup.atlassian.net/l/cp/YpXj111Z'
      }
    },

    // Fahrzeugmarkt Customers
    {
      path: '/customers/overview',
      name: 'customers-overview',
      components: {
        default: () =>
          import(
            /* webpackChunkName: "customers" */ '@/views/customers/Customers.vue'
          ),
        sideSheet: () =>
          import(
            /* webpackChunkName: "customers" */ '@/views/customers/CustomersSideSheet.vue'
          ),
        appBar: () =>
          import(
            /* webpackChunkName: "customers" */ '@/views/customers/AppBar.vue'
          )
      },
      meta: {
        requiresAuth: true,
        requiresPermissions: ['fahrzeugmarkt.customers.show'],
        // requiresClients: ['fahrzeugmarkt.online'],
        title: 'Kunden',
        knowledgeBaseUrl: 'https://freshgroup.atlassian.net/l/c/0AGnN89D'
      }
    },
    {
      path: '/customers/customer/:id?',
      name: 'customers-customer',
      components: {
        default: () =>
          import(
            /* webpackChunkName: "customers-customer" */ '@/views/customers/Customer.vue'
          ),
        appBar: () =>
          import(
            /* webpackChunkName: "customers" */ '@/views/customers/AppBar.vue'
          )
      },
      props: {
        default: (route) => ({
          id: route.params.id,
          mail: route.query.mail
        })
      },
      meta: {
        requiresAuth: true,
        requiresPermissions: ['fahrzeugmarkt.customers.show'],
        // requiresClients: ['fahrzeugmarkt.online'],
        title: 'Kunde',
        knowledgeBaseUrl: 'https://freshgroup.atlassian.net/l/c/0AGnN89D'
      }
    },

    // Leads
    {
      path: '/leads/import',
      name: 'leads-import',
      components: {
        default: () =>
          import(
            /* webpackChunkName: "leads" */ '@/views/leads/LeadsImport.vue'
          ),
        appBar: () =>
          import(/* webpackChunkName: "leads" */ '@/views/leads/AppBar.vue')
      },
      meta: {
        requiresAuth: true,
        requiresPermissions: ['fahrzeugmarkt.leads.import'],
        // requiresClients: ['fahrzeugmarkt.online'],
        title: 'Leads',
        knowledgeBaseUrl: 'https://freshgroup.atlassian.net/l/c/uDFnCRgD'
      }
    },
    {
      path: '/leads/leads',
      name: 'leads',
      components: {
        default: () =>
          import(/* webpackChunkName: "leads" */ '@/views/leads/Leads.vue'),
        appBar: () =>
          import(/* webpackChunkName: "leads" */ '@/views/leads/AppBar.vue'),
        sideSheet: () =>
          import(
            /* webpackChunkName: "leads" */ '@/views/leads/LeadsSideSheet.vue'
          )
      },
      meta: {
        requiresAuth: true,
        requiresPermissions: ['fahrzeugmarkt.leads.show'],
        // requiresClients: ['fahrzeugmarkt.online'],
        title: 'Leads',
        knowledgeBaseUrl: 'https://freshgroup.atlassian.net/l/c/uDFnCRgD'
      }
    },
    {
      path: '/leads/lead/:id?',
      alias: ['/fahrzeugmarkt/fahrzeuglead/:id?'],
      name: 'lead',
      components: {
        default: () =>
          import(/* webpackChunkName: "lead" */ '@/views/leads/Lead.vue'),
        appBar: () =>
          import(/* webpackChunkName: "lead" */ '@/views/leads/AppBar.vue')
      },
      props: {
        default: (route) => ({
          id: route.params.id,
          mail: route.query.mail
        })
      },
      meta: {
        requiresAuth: true,
        requiresPermissions: ['fahrzeugmarkt.leads.show'],
        // requiresClients: ['fahrzeugmarkt.online'],
        title: 'Leads',
        knowledgeBaseUrl: 'https://freshgroup.atlassian.net/l/c/uDFnCRgD'
      }
    },
    {
      path: '/leads/feedback',
      name: 'leadfeedbacks',
      components: {
        default: () =>
          import(
            /* webpackChunkName: "leadfeedbacks" */ '@/views/leads/Leadfeedbacks.vue'
          ),
        appBar: () =>
          import(
            /* webpackChunkName: "leadfeedbacks" */ '@/views/leads/AppBar.vue'
          ),
        sideSheet: () =>
          import(
            /* webpackChunkName: "leadfeedbacks" */ '@/views/feedback/FeedbacksSideSheet.vue'
          )
      },
      meta: {
        requiresAuth: true,
        requiresPermissions: ['fahrzeugmarkt.leads.show'],
        // requiresClients: ['fahrzeugmarkt.online'],
        title: 'Bewertungen',
        knowledgeBaseUrl: 'https://freshgroup.atlassian.net/l/c/DJdkN4s1'
      }
    },

    // Fahrzeugmarkt
    {
      path: '/fahrzeugmarkt',
      name: 'fahrzeugmarkt',
      redirect: { name: 'fahrzeugmarkt-fahrzeuge' }
    },

    {
      path: '/fahrzeugmarkt/valuations',
      name: 'fahrzeugmarkt-valuations',
      components: {
        default: () =>
          import(
            /* webpackChunkName: "fahrzeugmarkt-valuations" */ '@/views/fahrzeugmarkt/Valuations.vue'
          ),
        appBar: () =>
          import(
            /* webpackChunkName: "fahrzeugmarkt-valuations" */ '@/views/fahrzeugmarkt/AppBar.vue'
          )
      },
      meta: {
        requiresAuth: true,
        requiresPermissions: ['fahrzeugmarkt.fahrzeuge.show'],
        // requiresClients: ['fahrzeugmarkt.online'],
        title: 'Fahrzeugbewertungen',
        knowledgeBaseUrl: 'https://freshgroup.atlassian.net/l/cp/ikzJV7ov'
      }
    },

    {
      path: '/fahrzeugmarkt/trade-in-processes',
      name: 'fahrzeugmarkt-trade-in-processes',
      components: {
        default: () =>
          import(
            /* webpackChunkName: "fahrzeugmarkt-trade-in-processes" */ '@/views/fahrzeugmarkt/TradeInProcesses.vue'
          ),
        appBar: () =>
          import(
            /* webpackChunkName: "fahrzeugmarkt-trade-in-processes" */ '@/views/fahrzeugmarkt/AppBar.vue'
          )
      },
      meta: {
        requiresAuth: true,
        requiresPermissions: ['fahrzeugmarkt.fahrzeuge.show'],
        // requiresClients: ['fahrzeugmarkt.online'],
        title: 'Ankaufprozesse',
        knowledgeBaseUrl: 'https://freshgroup.atlassian.net/l/cp/usU2Nxn9'
      }
    },

    {
      path: '/fahrzeugmarkt/trade-in-process/:id?',
      name: 'fahrzeugmarkt-trade-in-process',
      components: {
        default: () =>
          import(
            /* webpackChunkName: "fahrzeugmarkt-trade-in-process" */ '@/views/fahrzeugmarkt/TradeInProcess.vue'
          ),
        appBar: () =>
          import(
            /* webpackChunkName: "fahrzeugmarkt-trade-in-process" */ '@/views/fahrzeugmarkt/AppBar.vue'
          )
      },
      props: {
        default: true
      },
      meta: {
        requiresAuth: true,
        requiresPermissions: ['fahrzeugmarkt.fahrzeuge.show'],
        // requiresClients: ['fahrzeugmarkt.online'],
        title: 'Ankaufprozess',
        knowledgeBaseUrl: 'https://freshgroup.atlassian.net/l/cp/usU2Nxn9'
      }
    },

    {
      path: '/fahrzeugmarkt/fahrzeuge',
      name: 'fahrzeugmarkt-fahrzeuge',
      components: {
        default: () =>
          import(
            /* webpackChunkName: "fahrzeugmarkt-fahrzeuge" */ '@/views/fahrzeugmarkt/Fahrzeuge.vue'
          ),
        appBar: () =>
          import(
            /* webpackChunkName: "fahrzeugmarkt-fahrzeuge" */ '@/views/fahrzeugmarkt/AppBar.vue'
          ),
        sideSheet: () =>
          import(
            /* webpackChunkName: "fahrzeugmarkt-fahrzeuge" */ '@/views/fahrzeugmarkt/FahrzeugeSideSheet.vue'
          )
      },
      meta: {
        requiresAuth: true,
        requiresPermissions: ['fahrzeugmarkt.fahrzeuge.show'],
        // requiresClients: ['fahrzeugmarkt.online'],
        title: 'Fahrzeugbestand',
        knowledgeBaseUrl: 'https://freshgroup.atlassian.net/l/c/sfLViWow'
      }
    },

    {
      path: '/fahrzeugmarkt/fahrzeug/edit/:id?',
      name: 'fahrzeugmarkt-fahrzeug',
      components: {
        default: () =>
          import(
            /* webpackChunkName: "fahrzeugmarkt-fahrzeug" */ '@/views/fahrzeugmarkt/Fahrzeug.vue'
          ),
        appBar: () =>
          import(
            /* webpackChunkName: "fahrzeugmarkt-fahrzeug" */ '@/views/fahrzeugmarkt/AppBar.vue'
          )
      },
      props: {
        default: true
      },
      meta: {
        requiresAuth: true,
        requiresPermissions: ['fahrzeugmarkt.fahrzeuge.show'],
        // requiresClients: ['fahrzeugmarkt.online'],
        title: 'Fahrzeugbestand',
        knowledgeBaseUrl: 'https://freshgroup.atlassian.net/l/c/sfLViWow'
      }
    },

    {
      path: '/fahrzeugmarkt/fahrzeug/:id?',
      name: 'fahrzeugmarkt-fahrzeug-details',
      components: {
        default: () =>
          import(
            /* webpackChunkName: "fahrzeugmarkt-fahrzeug" */ '@/views/fahrzeugmarkt/FahrzeugDetails.vue'
          ),
        appBar: () =>
          import(
            /* webpackChunkName: "fahrzeugmarkt-fahrzeug" */ '@/views/fahrzeugmarkt/AppBar.vue'
          )
      },
      props: {
        default: true
      },
      meta: {
        requiresAuth: true,
        requiresPermissions: ['fahrzeugmarkt.fahrzeuge.show'],
        // requiresClients: ['fahrzeugmarkt.online'],
        title: 'Fahrzeugbestand',
        knowledgeBaseUrl: 'https://freshgroup.atlassian.net/l/c/sfLViWow'
      }
    },

    // Fahrzeug - Fahrzeugortungen
    {
      path: '/fahrzeugmarkt/fahrzeuglocations',
      name: 'fahrzeugmarkt-fahrzeuglocations',
      components: {
        default: () =>
          import(
            /* webpackChunkName: "fahrzeugmarkt-fahrzeuglocations" */ '@/views/fahrzeugmarkt/Fahrzeuglocations.vue'
          ),
        appBar: () =>
          import(
            /* webpackChunkName: "fahrzeugmarkt-fahrzeuglocations" */ '@/views/fahrzeugmarkt/AppBar.vue'
          ),
        sideSheet: () =>
          import(
            /* webpackChunkName: "fahrzeugmarkt-fahrzeuglocations" */ '@/views/fahrzeugmarkt/FahrzeuglocationsSideSheet.vue'
          )
      },
      meta: {
        requiresAuth: true,
        requiresPermissions: ['fahrzeugmarkt.fahrzeuge.show'],
        // requiresClients: ['fahrzeugmarkt.online'],
        title: 'Fahrzeugortung',
        knowledgeBaseUrl: 'https://freshgroup.atlassian.net/l/cp/sBxB1QKX'
      }
    },

    // Fahrzeug - HU/AU Erinnerungsstop
    {
      path: '/inspectionreminderstop',
      name: 'inspectionreminderstop',
      component: () =>
        import('@/views/fahrzeugmarkt/Inspectionreminderstop.vue'),
      props: (route) => ({
        id: route.query.id,
        token: route.query.token
      }),
      meta: {
        requiresAuth: false
      }
    },

    {
      path: '/fahrzeugmarkt/fahrzeugangebote',
      name: 'fahrzeugmarkt-fahrzeugangebote',
      components: {
        default: () =>
          import(
            /* webpackChunkName: "fahrzeugmarkt-fahrzeugangebote" */ '@/views/fahrzeugmarkt/Fahrzeugangebote.vue'
          ),
        appBar: () =>
          import(
            /* webpackChunkName: "fahrzeugmarkt-fahrzeugangebote" */ '@/views/fahrzeugmarkt/AppBar.vue'
          ),
        sideSheet: () =>
          import(
            /* webpackChunkName: "fahrzeugmarkt-fahrzeugangebote" */ '@/views/fahrzeugmarkt/FahrzeugangeboteSideSheet.vue'
          )
      },
      meta: {
        requiresAuth: true,
        requiresPermissions: ['fahrzeugmarkt.angebote.show'],
        // requiresClients: ['fahrzeugmarkt.online'],
        title: 'Fahrzeuginserate',
        knowledgeBaseUrl: 'https://freshgroup.atlassian.net/l/c/5Ur5mHTe'
      }
    },

    {
      path: '/fahrzeugmarkt/fahrzeugangebot/:id?',
      name: 'fahrzeugmarkt-fahrzeugangebot-details',
      components: {
        default: () =>
          import(
            /* webpackChunkName: "fahrzeugmarkt-fahrzeug" */ '@/views/fahrzeugmarkt/FahrzeugangebotDetails.vue'
          ),
        appBar: () =>
          import(
            /* webpackChunkName: "fahrzeugmarkt-fahrzeugangebote" */ '@/views/fahrzeugmarkt/AppBar.vue'
          )
      },
      props: {
        default: true
      },
      meta: {
        requiresAuth: true,
        requiresPermissions: ['fahrzeugmarkt.angebote.show'],
        // requiresClients: ['fahrzeugmarkt.online'],
        title: 'Fahrzeuginserate',
        knowledgeBaseUrl: 'https://freshgroup.atlassian.net/l/c/5Ur5mHTe'
      }
    },

    {
      path: '/fahrzeugmarkt/contracts',
      name: 'fahrzeugmarkt-contracts',
      redirect: { name: 'fahrzeugmarkt-contracts-buy' }
    },
    {
      path: '/fahrzeugmarkt/contracts/buy',
      name: 'fahrzeugmarkt-contracts-buy',
      components: {
        default: () =>
          import(
            /* webpackChunkName: "fahrzeugmarkt-contracts" */ '@/views/fahrzeugmarkt/ContractsBuy.vue'
          ),
        appBar: () =>
          import(
            /* webpackChunkName: "fahrzeugmarkt-contracts" */ '@/views/fahrzeugmarkt/AppBar.vue'
          ),
        sideSheet: () =>
          import(
            /* webpackChunkName: "fahrzeugmarkt-contracts" */ '@/views/fahrzeugmarkt/ContractsBuySideSheet.vue'
          )
      },
      meta: {
        requiresAuth: true,
        requiresPermissions: ['fahrzeugmarkt.fahrzeuge.show'],
        // requiresClients: ['fahrzeugmarkt.online'],
        title: 'Ankaufscheine',
        knowledgeBaseUrl: 'https://freshgroup.atlassian.net/l/cp/W3TLqqea'
      }
    },
    {
      path: '/fahrzeugmarkt/contracts/sell',
      name: 'fahrzeugmarkt-contracts-sell',
      components: {
        default: () =>
          import(
            /* webpackChunkName: "fahrzeugmarkt-contracts" */ '@/views/fahrzeugmarkt/ContractsSell.vue'
          ),
        appBar: () =>
          import(
            /* webpackChunkName: "fahrzeugmarkt-contracts" */ '@/views/fahrzeugmarkt/AppBar.vue'
          ),
        sideSheet: () =>
          import(
            /* webpackChunkName: "fahrzeugmarkt-contracts" */ '@/views/fahrzeugmarkt/ContractsSellSideSheet.vue'
          )
      },
      meta: {
        requiresAuth: true,
        requiresPermissions: ['fahrzeugmarkt.fahrzeuge.show'],
        // requiresClients: ['fahrzeugmarkt.online'],
        title: 'Kaufverträge',
        knowledgeBaseUrl: 'https://freshgroup.atlassian.net/l/cp/W3TLqqea'
      }
    },
    {
      path: '/fahrzeugmarkt/contracts/order',
      name: 'fahrzeugmarkt-contracts-order',
      components: {
        default: () =>
          import(
            /* webpackChunkName: "fahrzeugmarkt-contracts" */ '@/views/fahrzeugmarkt/ContractsOrder.vue'
          ),
        appBar: () =>
          import(
            /* webpackChunkName: "fahrzeugmarkt-contracts" */ '@/views/fahrzeugmarkt/AppBar.vue'
          ),
        sideSheet: () =>
          import(
            /* webpackChunkName: "fahrzeugmarkt-contracts" */ '@/views/fahrzeugmarkt/ContractsOrderSideSheet.vue'
          )
      },
      meta: {
        requiresAuth: true,
        requiresPermissions: ['fahrzeugmarkt.fahrzeuge.show'],
        // requiresClients: ['fahrzeugmarkt.online'],
        title: 'Auftragsbestätigungen',
        knowledgeBaseUrl: 'https://freshgroup.atlassian.net/l/cp/W3TLqqea'
      }
    },
    {
      path: '/fahrzeugmarkt/contracts/rental',
      name: 'fahrzeugmarkt-contracts-rental',
      components: {
        default: () =>
          import(
            /* webpackChunkName: "fahrzeugmarkt-contracts" */ '@/views/fahrzeugmarkt/Rentalcontracts.vue'
          ),
        appBar: () =>
          import(
            /* webpackChunkName: "fahrzeugmarkt-contracts" */ '@/views/fahrzeugmarkt/AppBar.vue'
          ),
        sideSheet: () =>
          import(
            /* webpackChunkName: "fahrzeugmarkt-contracts" */ '@/views/fahrzeugmarkt/RentalcontractsSideSheet.vue'
          )
      },
      meta: {
        requiresAuth: true,
        requiresPermissions: ['fahrzeugmarkt.fahrzeuge.show'],
        // requiresClients: ['fahrzeugmarkt.online'],
        title: 'Leihverträge',
        knowledgeBaseUrl: 'https://freshgroup.atlassian.net/l/cp/W3TLqqea'
      }
    },

    {
      path: '/fahrzeugmarkt/emailings',
      name: 'fahrzeugmarkt-emailings',
      components: {
        default: () =>
          import(
            /* webpackChunkName: "fahrzeugmarkt-emailings" */ '@/views/fahrzeugmarkt/Emailings.vue'
          ),
        appBar: () =>
          import(
            /* webpackChunkName: "fahrzeugmarkt-emailings" */ '@/views/fahrzeugmarkt/AppBar.vue'
          )
      },
      meta: {
        requiresAuth: true,
        requiresPermissions: ['fahrzeugmarkt.emailings.show'],
        // requiresClients: ['fahrzeugmarkt.online'],
        title: 'E-Mailings',
        knowledgeBaseUrl: 'https://freshgroup.atlassian.net/l/c/Ei2ihP21'
      }
    },

    {
      path: '/fahrzeugmarkt/emailing/:id?',
      name: 'fahrzeugmarkt-emailing',
      components: {
        default: () =>
          import(
            /* webpackChunkName: "fahrzeugmarkt-emailing" */ '@/views/fahrzeugmarkt/Emailing.vue'
          ),
        appBar: () =>
          import(
            /* webpackChunkName: "fahrzeugmarkt-emailing" */ '@/views/fahrzeugmarkt/AppBar.vue'
          )
      },
      props: {
        default: true
      },
      meta: {
        requiresAuth: true,
        requiresPermissions: ['fahrzeugmarkt.emailings.show'],
        // requiresClients: ['fahrzeugmarkt.online'],
        title: 'E-Mailings',
        knowledgeBaseUrl: 'https://freshgroup.atlassian.net/l/c/Ei2ihP21'
      }
    },

    // Fahrzeugmarkt Werkstatt
    {
      path: '/workshop/storages',
      name: 'workshop-storages',
      components: {
        default: () =>
          import(
            /* webpackChunkName: "workshop-storages" */ '@/views/workshop/Storages.vue'
          ),
        appBar: () =>
          import(
            /* webpackChunkName: "workshop-storages" */ '@/views/workshop/AppBar.vue'
          ),
        sideSheet: () =>
          import(
            /* webpackChunkName: "workshop-storages" */ '@/views/workshop/StoragesSideSheet.vue'
          )
      },
      meta: {
        requiresAuth: true,
        requiresPermissions: ['fahrzeugmarkt.storages.show'],
        // requiresClients: ['fahrzeugmarkt.online'],
        title: 'Einlagerung',
        knowledgeBaseUrl: 'https://freshgroup.atlassian.net/l/c/0AGnN89D'
      }
    },
    {
      path: '/workshop/storage/:id?',
      name: 'workshop-storage',
      components: {
        default: () =>
          import(
            /* webpackChunkName: "workshop-storage" */ '@/views/workshop/Storage.vue'
          ),
        appBar: () =>
          import(
            /* webpackChunkName: "workshop-storage" */ '@/views/workshop/AppBar.vue'
          )
      },
      props: {
        default: true
      },
      meta: {
        requiresAuth: true,
        requiresPermissions: ['fahrzeugmarkt.storages.show'],
        // requiresClients: ['fahrzeugmarkt.online'],
        title: 'Einlagerung',
        knowledgeBaseUrl: 'https://freshgroup.atlassian.net/l/c/0AGnN89D'
      }
    },
    {
      path: '/workshop/workshoprepairreports',
      name: 'workshop-workshoprepairreports',
      components: {
        default: () =>
          import(
            /* webpackChunkName: "workshop-workshoprepairreports" */ '@/views/workshop/Workshoprepairreports.vue'
          ),
        appBar: () =>
          import(
            /* webpackChunkName: "workshop-workshoprepairreports" */ '@/views/workshop/AppBar.vue'
          )
      },
      meta: {
        requiresAuth: true,
        requiresPermissions: ['fahrzeugmarkt.fahrzeuge.show'],
        // requiresClients: ['fahrzeugmarkt.online'],
        title: 'Technische Bewertungen',
        knowledgeBaseUrl: 'https://freshgroup.atlassian.net/l/cp/usU2Nxn9'
      }
    },
    {
      path: '/workshop/workshoprepairreport/:id?',
      name: 'workshop-workshoprepairreport',
      components: {
        default: () =>
          import(
            /* webpackChunkName: "workshop-workshoprepairreport" */ '@/views/workshop/Workshoprepairreport.vue'
          ),
        appBar: () =>
          import(
            /* webpackChunkName: "workshop-workshoprepairreport" */ '@/views/workshop/AppBar.vue'
          )
      },
      props: {
        default: (route) => ({
          id: route.params.id,
          mode: route.params.mode,
          tradeInProcessId:
            route.params.tradeInProcessId ?? route.query.tradeInProcess,
          vehicleId: route.params.vehicle
        })
      },
      meta: {
        requiresAuth: true,
        requiresPermissions: ['fahrzeugmarkt.fahrzeuge.show'],
        // requiresClients: ['fahrzeugmarkt.online'],
        title: 'Instandsetzungsbericht',
        knowledgeBaseUrl: 'https://freshgroup.atlassian.net/l/cp/usU2Nxn9'
      }
    },

    // Fahrzeugmarkt Rechnungen
    {
      path: '/invoices/overview',
      name: 'invoices-overview',
      components: {
        default: () =>
          import(
            /* webpackChunkName: "invoices" */ '@/views/invoices/Invoices.vue'
          ),
        sideSheet: () =>
          import(
            /* webpackChunkName: "invoices" */ '@/views/invoices/InvoicesSideSheet.vue'
          ),
        appBar: () =>
          import(
            /* webpackChunkName: "invoices" */ '@/views/invoices/AppBar.vue'
          )
      },
      meta: {
        requiresAuth: true,
        requiresPermissions: ['fahrzeugmarkt.invoices.show'],
        // requiresClients: ['fahrzeugmarkt.online'],
        title: 'Rechnungen',
        knowledgeBaseUrl: 'https://freshgroup.atlassian.net/l/cp/1BarTWqu'
      }
    },

    // Fahrzeugmarkt Statistiken
    {
      path: '/controlling/statistics',
      name: 'controlling-statistics',
      components: {
        default: () =>
          import(
            /* webpackChunkName: "controlling-statistics" */ '@/views/controlling/Statistics.vue'
          )
      },
      props: {
        default: true
      },
      meta: {
        requiresAuth: true,
        requiresPermissions: ['fahrzeugmarkt.statistics.show'],
        // requiresClients: ['fahrzeugmarkt.online'],
        title: 'Statistiken',
        knowledgeBaseUrl: 'https://freshgroup.atlassian.net/l/c/doqYE05D'
      }
    },
    {
      path: '/controlling/reports',
      name: 'controlling-reports',
      components: {
        default: () =>
          import(
            /* webpackChunkName: "controlling-reports" */ '@/views/controlling/Reports.vue'
          )
      },
      meta: {
        requiresAuth: true,
        requiresPermissions: ['fahrzeugmarkt.statistics.show'],
        // requiresClients: ['fahrzeugmarkt.online'],
        title: 'Reports',
        knowledgeBaseUrl: 'https://freshgroup.atlassian.net/l/c/doqYE05D'
      }
    },

    // Dokumentencenter
    {
      path: '/filecenter',
      name: 'filecenter',
      component: () => import('@/views/filecenter/Dashboard.vue'),
      meta: {
        requiresAuth: true,
        requiresPermissions: ['filecenter.show'],
        title: 'Dokumentencenter',
        knowledgeBaseUrl: 'https://freshgroup.atlassian.net/l/c/fQ7huahj'
      }
    },

    // Quicklinks
    {
      path: '/quicklinks',
      name: 'quicklinks',
      component: () => import('@/views/quicklinks/Dashboard.vue'),
      meta: {
        requiresAuth: true,
        requiresPermissions: ['quicklinks.show'],
        title: 'Quicklinks',
        knowledgeBaseUrl: 'https://freshgroup.atlassian.net/l/c/p1VViFx2'
      }
    },

    // Systempartner
    {
      path: '/systempartner/carsharing',
      name: 'systempartner-carsharing',
      components: {
        default: () =>
          import(
            /* webpackChunkName: "systempartner" */ '@/views/systempartner/Carsharingmanagement.vue'
          ),
        sideSheet: () =>
          import(
            /* webpackChunkName: "systempartner" */ '@/views/systempartner/CarsharingmanagementSideSheet.vue'
          )
      },
      meta: {
        requiresAuth: true,
        requiresPermissions: ['carsharingmanagement.show'],
        title: 'Carsharing',
        knowledgeBaseUrl: 'https://freshgroup.atlassian.net/l/c/eHu4NLWB'
      }
    },
    {
      path: '/systempartner/autohaeuser',
      name: 'systempartner-autohaeuser',
      components: {
        default: () =>
          import(
            /* webpackChunkName: "systempartner" */ '@/views/systempartner/Autohausverwaltung.vue'
          )
      },
      meta: {
        requiresAuth: true,
        requiresPermissions: ['autohausverwaltung.show'],
        title: 'Autohäuser',
        knowledgeBaseUrl: 'https://freshgroup.atlassian.net/l/c/VnGr404g'
      }
    },
    {
      path: '/systempartner/produktpartner',
      name: 'systempartner-produktpartner',
      components: {
        default: () =>
          import(
            /* webpackChunkName: "systempartner" */ '@/views/systempartner/Produktpartnerverwaltung.vue'
          ),
        sideSheet: () =>
          import(
            /* webpackChunkName: "systempartner" */ '@/views/systempartner/ProduktpartnerverwaltungSideSheet.vue'
          )
      },
      meta: {
        requiresAuth: true,
        requiresPermissions: ['produktpartnerverwaltung.show'],
        title: 'Produktpartner',
        knowledgeBaseUrl: 'https://freshgroup.atlassian.net/l/c/fRoSRLS8'
      }
    },
    {
      path: '/systempartner/employermanagement',
      name: 'systempartner-employermanagement',
      components: {
        default: () =>
          import(
            /* webpackChunkName: "systempartner" */ '@/views/systempartner/Employermanagement.vue'
          ),
        sideSheet: () =>
          import(
            /* webpackChunkName: "systempartner" */ '@/views/systempartner/EmployermanagementSideSheet.vue'
          )
      },
      meta: {
        requiresAuth: true,
        requiresPermissions: ['employermanagement.show'],
        title: 'Firmen',
        knowledgeBaseUrl: 'https://freshgroup.atlassian.net/l/c/pLuU1pNT'
      }
    },

    // Controlling Carsharing
    {
      path: '/controlling/carsharing',
      name: 'controlling-carsharing',
      redirect: { name: 'controlling-carsharing-statistics' }
    },
    {
      path: '/controlling/carsharing/statistics',
      name: 'controlling-carsharing-statistics',
      component: () =>
        import(
          /* webpackChunkName: "controlling-carsharing" */ '@/views/controlling/carsharing/Statistics.vue'
        ),
      meta: {
        requiresAuth: true,
        requiresPermissions: ['carsharingmanagement.show'],
        title: 'Carsharing Übersicht',
        knowledgeBaseUrl: 'https://freshgroup.atlassian.net/l/c/fQ7huahj'
      }
    },

    // Admin
    {
      path: '/administration',
      name: 'administration',
      component: () =>
        import(
          /* webpackChunkName: "admin" */ '@/views/admin/Administration.vue'
        ),
      meta: {
        requiresAuth: true,
        requiresPermissions: ['fahrzeugmarkt.admin.show'],
        title: 'Einstellungen',
        knowledgeBaseUrl: 'https://freshgroup.atlassian.net/l/c/N0YKqw5n'
      }
    },
    {
      path: '/administration/settings',
      name: 'administration-settings',
      component: () =>
        import(/* webpackChunkName: "admin" */ '@/views/admin/Settings.vue'),
      props: true,
      meta: {
        requiresAuth: true,
        requiresPermissions: ['fahrzeugmarkt.admin.show'],
        title: 'Einstellungen',
        knowledgeBaseUrl: 'https://freshgroup.atlassian.net/l/c/N0YKqw5n'
      }
    },

    {
      path: '/administration/users',
      name: 'administration-users',
      components: {
        default: () =>
          import(/* webpackChunkName: "admin" */ '@/views/admin/Users.vue')
      },
      meta: {
        requiresAuth: true,
        requiresPermissions: ['fahrzeugmarkt.admin.show'],
        title: 'Benutzer',
        knowledgeBaseUrl: 'https://freshgroup.atlassian.net/l/c/9z61H0F2'
      }
    },

    // System
    {
      path: '/system/mails',
      name: 'system-mails',
      components: {
        default: () =>
          import(/* webpackChunkName: "system" */ '@/views/system/Mails.vue'),
        sideSheet: () =>
          import(
            /* webpackChunkName: "system" */ '@/views/system/MailsSideSheet.vue'
          )
      },
      meta: {
        requiresAuth: true,
        requiresPermissions: ['system.show'],
        title: 'E-Mails (empfangen)',
        knowledgeBaseUrl: 'https://freshgroup.atlassian.net/l/c/fQ7huahj'
      }
    },
    {
      path: '/system/maillogs',
      name: 'system-maillogs',
      components: {
        default: () =>
          import(
            /* webpackChunkName: "system" */ '@/views/system/Maillogs.vue'
          ),
        sideSheet: () =>
          import(
            /* webpackChunkName: "system" */ '@/views/system/MaillogsSideSheet.vue'
          )
      },
      meta: {
        requiresAuth: true,
        requiresPermissions: ['system.show'],
        title: 'E-Mails (versendet)',
        knowledgeBaseUrl: 'https://freshgroup.atlassian.net/l/c/fQ7huahj'
      }
    },
    {
      path: '/system/emailings',
      name: 'system-emailings',
      components: {
        default: () =>
          import(
            /* webpackChunkName: "system" */ '@/views/system/Emailings.vue'
          ),
        sideSheet: () =>
          import(
            /* webpackChunkName: "system" */ '@/views/system/EmailingsSideSheet.vue'
          )
      },
      meta: {
        requiresAuth: true,
        requiresPermissions: ['system.show'],
        title: 'E-Mailings',
        knowledgeBaseUrl: 'https://freshgroup.atlassian.net/l/c/fQ7huahj'
      }
    },
    {
      path: '/system/emailing/:id?',
      name: 'system-emailing',
      component: () =>
        import(/* webpackChunkName: "system" */ '@/views/system/Emailing.vue'),
      props: true,
      meta: {
        requiresAuth: true,
        requiresPermissions: ['system.show'],
        title: 'E-Mailing',
        knowledgeBaseUrl: 'https://freshgroup.atlassian.net/l/c/fQ7huahj'
      }
    },
    {
      path: '/system/signature-requests',
      name: 'system-signature-requests',
      components: {
        default: () =>
          import(
            /* webpackChunkName: "system" */ '@/views/system/SignatureRequests.vue'
          ),
        sideSheet: () =>
          import(
            /* webpackChunkName: "system" */ '@/views/system/SignatureRequestsSideSheet.vue'
          )
      },
      meta: {
        requiresAuth: true,
        requiresPermissions: ['system.show'],
        title: 'Signatur-Anfragen',
        knowledgeBaseUrl: 'https://freshgroup.atlassian.net/l/c/fQ7huahj'
      }
    },
    {
      path: '/system/users',
      name: 'system-users',
      components: {
        default: () =>
          import(/* webpackChunkName: "system" */ '@/views/system/Users.vue'),
        sideSheet: () =>
          import(
            /* webpackChunkName: "system" */ '@/views/system/UsersSideSheet.vue'
          )
      },
      meta: {
        requiresAuth: true,
        requiresPermissions: ['system.show'],
        title: 'Users',
        knowledgeBaseUrl: 'https://freshgroup.atlassian.net/l/c/fQ7huahj'
      }
    },
    {
      path: '/system/imports',
      name: 'system-imports',
      components: {
        default: () =>
          import(
            /* webpackChunkName: "controlling" */ '@/views/system/Imports.vue'
          )
        // sideSheet: () =>
        //   import(
        //     /* webpackChunkName: "controlling" */ '@/views/system/ImportsSideSheet.vue'
        //   )
      },
      meta: {
        requiresAuth: true,
        requiresPermissions: ['system.show'],
        title: 'Imports',
        knowledgeBaseUrl: 'https://freshgroup.atlassian.net/l/c/fQ7huahj'
      }
    },
    {
      path: '/system/settings/system/mail',
      name: 'settings-system-system-mail',
      component: () => import('@/components/settings/settingssystem/Main.vue'),
      props: {
        bereich: 'allgemein',
        unterbereich: 'mailversand'
      },
      meta: {
        requiresAuth: true,
        requiresPermissions: [
          'settings.system.edit',
          'settings.system.edit.ALLGEMEIN.MAILVERSAND'
        ],
        title: 'System Mailversand',
        knowledgeBaseUrl: 'https://freshgroup.atlassian.net/l/c/fQ7huahj'
      }
    },
    {
      path: '/system/settings/system/message',
      name: 'settings-system-system-message',
      component: () => import('@/components/settings/settingssystem/Main.vue'),
      props: {
        bereich: 'system',
        unterbereich: 'message'
      },
      meta: {
        requiresAuth: true,
        requiresPermissions: [
          'settings.system.edit',
          'settings.system.edit.SYSTEM.MESSAGE'
        ],
        title: 'System Message',
        knowledgeBaseUrl: 'https://freshgroup.atlassian.net/l/c/fQ7huahj'
      }
    },
    {
      path: '/system/settings/antrag/freshcarflat',
      name: 'settings-system-antrag-freshcarflat',
      component: () => import('@/components/settings/settingssystem/Main.vue'),
      props: {
        bereich: 'antrag',
        unterbereich: 'freshcarflat'
      },
      meta: {
        requiresAuth: true,
        requiresPermissions: [
          'settings.system.edit',
          'settings.system.edit.ANTRAG.FRESHCARFLAT'
        ],
        title: 'Antrag-Einstellungen',
        knowledgeBaseUrl: 'https://freshgroup.atlassian.net/l/c/fQ7huahj'
      }
    },
    {
      path: '/system/settings/antrag/freshcarsharing',
      name: 'settings-system-antrag-freshcarsharing',
      component: () => import('@/components/settings/settingssystem/Main.vue'),
      props: {
        bereich: 'antrag',
        unterbereich: 'freshcarsharing'
      },
      meta: {
        requiresAuth: true,
        requiresPermissions: [
          'settings.system.edit',
          'settings.system.edit.ANTRAG.FRESHCARSHARING'
        ],
        title: 'Antrag-Einstellungen',
        knowledgeBaseUrl: 'https://freshgroup.atlassian.net/l/c/fQ7huahj'
      }
    },
    {
      path: '/system/settings/antrag/freshbauimmo',
      name: 'settings-system-antrag-freshbauimmo',
      component: () => import('@/components/settings/settingssystem/Main.vue'),
      props: {
        bereich: 'antrag',
        unterbereich: 'freshbauimmo'
      },
      meta: {
        requiresAuth: true,
        requiresPermissions: [
          'settings.system.edit',
          'settings.system.edit.ANTRAG.FRESHBAUIMMO'
        ],
        title: 'Antrag-Einstellungen',
        knowledgeBaseUrl: 'https://freshgroup.atlassian.net/l/c/fQ7huahj'
      }
    },
    {
      path: '/system/settings/antrag/freshbike',
      name: 'settings-system-antrag-freshbike',
      component: () => import('@/components/settings/settingssystem/Main.vue'),
      props: {
        bereich: 'antrag',
        unterbereich: 'freshbike'
      },
      meta: {
        requiresAuth: true,
        requiresPermissions: [
          'settings.system.edit',
          'settings.system.edit.ANTRAG.freshBIKE'
        ],
        title: 'Antrag-Einstellungen',
        knowledgeBaseUrl: 'https://freshgroup.atlassian.net/l/c/fQ7huahj'
      }
    },
    {
      path: '/system/settings/ratenzuschlag-allgemein',
      name: 'settings-system-ratenzuschlag-allgemein',
      component: () => import('@/components/settings/settingssystem/Main.vue'),
      props: {
        bereich: 'ratenzuschlag',
        unterbereich: 'allgemein'
      },
      meta: {
        requiresAuth: true,
        requiresPermissions: [
          'settings.system.edit',
          'settings.system.edit.RATENZUSCHLAG.ALLGEMEIN'
        ],
        title: 'Ratenzuschlag-Einstellungen',
        knowledgeBaseUrl: 'https://freshgroup.atlassian.net/l/c/fQ7huahj'
      }
    },

    // Tools
    {
      path: '/tools',
      name: 'tools',
      component: () =>
        import(/* webpackChunkName: "tools" */ '@/views/tools/Tools.vue'),

      meta: {
        requiresAuth: true,
        requiresPermissions: ['tools.background-removals'],
        title: 'Tools',
        knowledgeBaseUrl: {
          default: 'https://freshgroup.atlassian.net/l/cp/zrpxd4yF',
          freshforfinance: 'https://freshgroup.atlassian.net/l/cp/zrpxd4yF',
          'fahrzeugmarkt.online':
            'https://freshgroup.atlassian.net/l/cp/zmP2gTnm'
        }
      }
    },
    {
      path: '/tools/bg-removal-processes',
      name: 'tools-bg-removal-processes',
      component: () =>
        import(
          /* webpackChunkName: "tools" */ '@/views/tools/BgRemovalProcesses.vue'
        ),

      meta: {
        requiresAuth: true,
        requiresPermissions: ['tools.background-removals'],
        title: 'Hintergrundentfernungen',
        knowledgeBaseUrl: {
          default: 'https://freshgroup.atlassian.net/l/cp/VkjVo5nS',
          freshforfinance: 'https://freshgroup.atlassian.net/l/cp/VkjVo5nS',
          'fahrzeugmarkt.online':
            'https://freshgroup.atlassian.net/l/cp/1PphjSwb'
        }
      }
    },
    {
      path: '/tools/bg-removal-process/:id?',
      name: 'tools-bg-removal-process',
      component: () =>
        import(
          /* webpackChunkName: "tools" */ '@/views/tools/BgRemovalProcess.vue'
        ),
      props: true,
      meta: {
        requiresAuth: true,
        requiresPermissions: ['tools.background-removals'],
        title: 'Hintergrundentfernung',
        knowledgeBaseUrl: {
          default: 'https://freshgroup.atlassian.net/l/cp/VkjVo5nS',
          freshforfinance: 'https://freshgroup.atlassian.net/l/cp/VkjVo5nS',
          'fahrzeugmarkt.online':
            'https://freshgroup.atlassian.net/l/cp/1PphjSwb'
        }
      }
    },

    // Kontaktcenter
    {
      path: '/supportcenter',
      name: 'supportcenter',
      redirect: { name: 'issues' }
    },

    {
      path: '/supportcenter/issues',
      name: 'issues',
      components: {
        default: () =>
          import(
            /* webpackChunkName: "supportcenter" */ '@/views/supportcenter/Issues.vue'
          ),
        appBar: () =>
          import(
            /* webpackChunkName: "supportcenter" */ '@/views/supportcenter/AppBar.vue'
          )
      },
      meta: {
        requiresAuth: true,
        requiresPermissions: ['supportcenter.show'],
        title: 'Kontaktcenter - Übersicht',
        knowledgeBaseUrl: {
          default: 'https://freshgroup.atlassian.net/l/c/1wj0qFbZ',
          freshforfinance: 'https://freshgroup.atlassian.net/l/c/1wj0qFbZ',
          'fahrzeugmarkt.online':
            'https://freshgroup.atlassian.net/l/c/cHH3T1o1'
        }
      }
    },

    {
      path: '/supportcenter/issue/:id?',
      name: 'issue',
      components: {
        default: () =>
          import(
            /* webpackChunkName: "supportcenter" */ '@/views/supportcenter/Issue.vue'
          ),
        appBar: () =>
          import(
            /* webpackChunkName: "supportcenter" */ '@/views/supportcenter/AppBar.vue'
          )
      },
      props: {
        default: (route) => ({
          id: route.params.id,
          reason: route.query.reason,
          defaults: route.params.defaults
        })
      },
      meta: {
        requiresAuth: true,
        requiresPermissions: ['supportcenter.show'],
        title: 'Kontaktcenter - Nachricht',
        knowledgeBaseUrl: {
          default: 'https://freshgroup.atlassian.net/l/c/1wj0qFbZ',
          freshforfinance: 'https://freshgroup.atlassian.net/l/c/1wj0qFbZ',
          'fahrzeugmarkt.online':
            'https://freshgroup.atlassian.net/l/c/cHH3T1o1'
        }
      }
    },

    // User
    {
      path: '/user/administration',
      name: 'user-administration',
      component: () =>
        import(
          /* webpackChunkName: "user" */ '@/views/user/Administration.vue'
        ),
      props: true,
      meta: {
        requiresAuth: true,
        requiresPermissions: ['profile.show', 'vermittlerdaten.show'],
        title: 'Einstellungen',
        knowledgeBaseUrl: 'https://freshgroup.atlassian.net/l/c/3U15mmtK'
      }
    },
    {
      path: '/user/profile',
      name: 'user-profile',
      component: () =>
        import(/* webpackChunkName: "user" */ '@/views/user/Profil.vue'),
      meta: {
        requiresAuth: true,
        requiresPermissions: ['profile.show'],
        title: 'Profil',
        knowledgeBaseUrl: {
          default: 'https://freshgroup.atlassian.net/l/c/0LM9H790',
          freshforfinance: 'https://freshgroup.atlassian.net/l/c/0LM9H790',
          'fahrzeugmarkt.online':
            'https://freshgroup.atlassian.net/l/c/U09YjTHb'
        }
      }
    },
    {
      path: '/user/persoenliche-daten',
      name: 'user-persoenliche-daten',
      component: () =>
        import(
          /* webpackChunkName: "user" */ '@/views/user/PersoenlicheDaten.vue'
        ),
      meta: {
        requiresAuth: true,
        requiresPermissions: ['profile.show'],
        title: 'Persönliche Daten',
        knowledgeBaseUrl: {
          default: 'https://freshgroup.atlassian.net/l/c/0LM9H790',
          freshforfinance: 'https://freshgroup.atlassian.net/l/c/0LM9H790',
          'fahrzeugmarkt.online':
            'https://freshgroup.atlassian.net/l/c/U09YjTHb'
        }
      }
    },
    {
      path: '/user/settings',
      name: 'user-settings',
      component: () =>
        import(/* webpackChunkName: "user" */ '@/views/user/UserSettings.vue'),
      props: true,
      meta: {
        requiresAuth: true,
        requiresPermissions: ['profile.show'],
        requiresRoles: [
          'fahrzeugmarkt-standortuser',
          'fahrzeugmarkt-standortmanager',
          'fahrzeugmarkt-user',
          'fahrzeugmarkt-manager',
          'fahrzeugmarkt-admin'
        ],
        // requiresClients: ['fahrzeugmarkt.online'],
        title: 'Benutzer-Einstellungen',
        knowledgeBaseUrl: 'https://freshgroup.atlassian.net/l/c/3U15mmtK'
      }
    },
    {
      path: '/user/vermittlerdaten',
      name: 'user-vermittlerdaten',
      component: () =>
        import(
          /* webpackChunkName: "user" */ '@/views/user/Vermittlerdaten.vue'
        ),
      meta: {
        requiresAuth: true,
        requiresPermissions: ['vermittlerdaten.show'],
        title: 'Vermittlerdaten',
        knowledgeBaseUrl: {
          default: 'https://freshgroup.atlassian.net/l/c/0LM9H790',
          freshforfinance: 'https://freshgroup.atlassian.net/l/c/0LM9H790',
          'fahrzeugmarkt.online':
            'https://freshgroup.atlassian.net/l/c/U09YjTHb'
        }
      }
    },
    {
      path: '/user/history',
      name: 'user-history',
      component: () =>
        import(/* webpackChunkName: "user" */ '@/views/user/History.vue'),
      meta: {
        requiresAuth: true,
        requiresPermissions: ['history'],
        title: 'Historie',
        knowledgeBaseUrl: {
          default: 'https://freshgroup.atlassian.net/l/c/0LM9H790#Historie',
          freshforfinance:
            'https://freshgroup.atlassian.net/l/c/0LM9H790#Historie',
          'fahrzeugmarkt.online':
            'https://freshgroup.atlassian.net/l/c/U09YjTHb#Historie'
        }
      }
    },

    /**
     * BVGVUSER
     * Prefix: https://eks.bgv.de/bgv/owa/
     */
    {
      path: '/carsharing/partnerdaten',
      name: 'external-partnerdaten',
      component: () => import('@/views/carsharing/CarsharingIframeBgv.vue'),
      props: { mode: 'PARTNERDATEN' },
      meta: {
        requiresAuth: true,
        requiresPermissions: ['carsharing.show'],
        layout: {
          fillHeight: true,
          fluid: true
        },
        title: 'Partnerdaten',
        knowledgeBaseUrl: 'https://freshgroup.atlassian.net/l/c/HuVxaxDT'
      }
    },
    {
      path: '/user/carsharingcontactdata',
      name: 'user-carsharingcontactdata',
      component: () => import('@/views/user/Carsharingdata.vue'),
      meta: {
        requiresAuth: true,
        requiresPermissions: ['carsharingdata.show'],
        title: 'Kontaktdaten',
        knowledgeBaseUrl: 'https://freshgroup.atlassian.net/l/c/CyQytUAL'
      }
    },
    {
      path: '/user/carsharingcontractdata',
      name: 'user-carsharingcontractdata',
      component: () => import('@/views/user/CarsharingContractdata.vue'),
      meta: {
        requiresAuth: true,
        requiresPermissions: ['carsharingdata.show'],
        title: 'Carsharingdaten',
        knowledgeBaseUrl: 'https://freshgroup.atlassian.net/l/c/bwbeAihM'
      }
    },
    {
      path: '/carsharing/statistics/bestand',
      name: 'external-statistics-bestand',
      component: () => import('@/views/carsharing/StatisticsBestand.vue'),
      meta: {
        requiresAuth: true,
        requiresPermissions: ['carsharing.show'],
        title: 'Statistiken',
        knowledgeBaseUrl: 'https://freshgroup.atlassian.net/l/c/P8NmpDXA'
      }
    },
    {
      path: '/carsharing/objektsuche',
      name: 'external-objektsuche',
      component: () => import('@/views/carsharing/CarsharingIframeBgv.vue'),
      props: { mode: 'OBJEKTSUCHE' },
      meta: {
        requiresAuth: true,
        requiresPermissions: ['carsharing.show'],
        layout: {
          fillHeight: true,
          fluid: true
        },
        title: 'Objektsuche',
        knowledgeBaseUrl: 'https://freshgroup.atlassian.net/l/c/HuVxaxDT'
      }
    },
    {
      path: '/carsharing/vertragssuche',
      name: 'external-vertragssuche',
      component: () => import('@/views/carsharing/CarsharingIframeBgv.vue'),
      props: { mode: 'VERTRAGSSUCHE' },
      meta: {
        requiresAuth: true,
        requiresPermissions: ['carsharing.show'],
        layout: {
          fillHeight: true,
          fluid: true
        },
        title: 'Vertragssuche',
        knowledgeBaseUrl: 'https://freshgroup.atlassian.net/l/c/HuVxaxDT'
      }
    },
    {
      path: '/carsharing/vertragsliste',
      name: 'external-vertragsliste',
      component: () => import('@/views/carsharing/CarsharingIframeBgv.vue'),
      props: { mode: 'VERTRAGSLISTE' },
      meta: {
        requiresAuth: true,
        requiresPermissions: ['carsharing.show'],
        layout: {
          fillHeight: true,
          fluid: true
        },
        title: 'Vertragsliste',
        knowledgeBaseUrl: 'https://freshgroup.atlassian.net/l/c/HuVxaxDT'
      }
    },
    {
      path: '/carsharing/schadensuche',
      name: 'external-schadensuche',
      component: () => import('@/views/carsharing/CarsharingIframeBgv.vue'),
      props: { mode: 'SCHADENSUCHE' },
      meta: {
        requiresAuth: true,
        requiresPermissions: ['carsharing.show'],
        layout: {
          fillHeight: true,
          fluid: true
        },
        title: 'Schadensuche',
        knowledgeBaseUrl: 'https://freshgroup.atlassian.net/l/c/HuVxaxDT'
      }
    },
    {
      path: '/carsharing/schadenliste',
      name: 'external-schadenliste',
      component: () => import('@/views/carsharing/CarsharingIframeBgv.vue'),
      props: { mode: 'SCHADENLISTE' },
      meta: {
        requiresAuth: true,
        requiresPermissions: ['carsharing.show'],
        layout: {
          fillHeight: true,
          fluid: true
        },
        title: 'Schadenliste',
        knowledgeBaseUrl: 'https://freshgroup.atlassian.net/l/c/HuVxaxDT'
      }
    },
    {
      path: '/carsharing/schadenquote',
      name: 'external-schadenquote',
      component: () => import('@/views/carsharing/CarsharingIframeBgv.vue'),
      props: { mode: 'SCHADENQUOTE' },
      meta: {
        requiresAuth: true,
        requiresPermissions: ['carsharing.show'],
        layout: {
          fillHeight: true,
          fluid: true
        },
        title: 'Schadenquote',
        knowledgeBaseUrl: 'https://freshgroup.atlassian.net/l/c/HuVxaxDT'
      }
    },
    {
      path: '/carsharing/kontenabfrage',
      name: 'external-kontenabfrage',
      component: () => import('@/views/carsharing/CarsharingIframeBgv.vue'),
      props: { mode: 'KONTENABFRAGE' },
      meta: {
        requiresAuth: true,
        requiresPermissions: ['carsharing.show'],
        layout: {
          fillHeight: true,
          fluid: true
        },
        title: 'Kontenabfrage',
        knowledgeBaseUrl: 'https://freshgroup.atlassian.net/l/c/HuVxaxDT'
      }
    },
    {
      path: '/carsharing/epostkorb',
      name: 'external-epostkorb',
      component: () => import('@/views/carsharing/CarsharingIframeBgv.vue'),
      props: { mode: 'EPOSTKORB' },
      meta: {
        requiresAuth: true,
        requiresPermissions: ['carsharing.show'],
        layout: {
          fillHeight: true,
          fluid: true
        },
        title: 'ePostkorb',
        knowledgeBaseUrl: 'https://freshgroup.atlassian.net/l/c/HuVxaxDT'
      }
    },

    /**
     * Sicherungsscheine
     */

    {
      path: '/carsharing/carsharinglessorcertificates/list',
      name: 'carsharing-carsharinglessorcertificates-list',
      components: {
        default: () =>
          import(
            /* webpackChunkName: "sicherungsscheine" */ '@/views/user/LessorCertificates.vue'
          ),
        appBar: () =>
          import(
            /* webpackChunkName: "sicherungsscheine" */ '@/views/user/LessorCertificatesAppBar.vue'
          ),
        sideSheet: () =>
          import(
            /* webpackChunkName: "sicherungsscheine" */ '@/views/user/LessorCertificatesSideSheet.vue'
          )
      },
      meta: {
        requiresAuth: true,
        requiresPermissions: ['carsharingdata.show'],
        title: 'Sicherungsscheine',
        knowledgeBaseUrl: 'https://freshgroup.atlassian.net/l/cp/n0HZJ1G1'
      }
    },
    {
      path: '/carsharing/carsharinglessorcertificates/create/:id?',
      name: 'carsharing-carsharinglessorcertificates-create',
      components: {
        default: () =>
          import(
            /* webpackChunkName: "sicherungsscheine" */ '@/views/user/LessorCertificatesCreate.vue'
          ),
        appBar: () =>
          import(
            /* webpackChunkName: "sicherungsscheine" */ '@/views/user/LessorCertificatesAppBar.vue'
          )
      },
      props: {
        default: true
      },
      meta: {
        requiresAuth: true,
        requiresPermissions: ['carsharingdata.show'],
        title: 'Sicherungsscheine',
        knowledgeBaseUrl: 'https://freshgroup.atlassian.net/l/cp/n0HZJ1G1'
      }
    },
    {
      path: '/carsharing/carsharinglessorcertificate/:id?',
      name: 'user-carsharinglessorcertificate-details',
      components: {
        default: () =>
          import(
            /* webpackChunkName: "sicherungsscheine" */ '@/views/user/LessorcertificateDetails.vue'
          ),
        appBar: () =>
          import(
            /* webpackChunkName: "sicherungsscheine" */ '@/views/user/LessorCertificatesAppBar.vue'
          )
      },
      props: {
        default: true
      },
      meta: {
        requiresAuth: true,
        requiresPermissions: ['carsharingdata.show'],
        title: 'Sicherungsschein',
        knowledgeBaseUrl: 'https://freshgroup.atlassian.net/l/cp/n0HZJ1G1'
      }
    },

    /**
     * Error-Pages
     */

    {
      path: '/errors/401',
      name: 'error-401',
      component: Error401
    },
    {
      path: '*',
      name: 'error-404',
      component: Error404
    }
  ]
})

router.afterEach((to) => {
  /**
   * We will set the sideSheetData from the params, if they are set (this is necessary to be able to set for example the filter from a internal route navigation:
   * to: {
   *  name: 'xxx',
   *  params: {
   *    sideSheetData: {
   *      filter: {
   *        id: 'XXX'
   *      }
   *    }
   *  }
   * }
   */
  const sideSheetDataFromParams = _.get(to, 'params.sideSheetData')
  if (sideSheetDataFromParams) {
    store.dispatch('layout/resetSideSheetData')
    store.commit('layout/setSideSheetData', { data: sideSheetDataFromParams })
  }
})

router.beforeEach((to, from, next) => {
  if (!isAllowed(to)) {
    if (store.getters['auth/isAuthorized']) {
      // the user is already logged in, so we will show him an error page
      next({
        name: 'error-401'
      })
    } else {
      // let the user login
      next({
        name: 'login',
        query: { redirect: to.fullPath }
      })
    }
  } else {
    next()
  }
})

function isAllowed(to) {
  const requiresAuth = to.meta.requiresAuth || false
  const requiresRoles = to.meta.requiresRoles || []
  const requiresPermissions = to.meta.requiresPermissions || []
  const requiresClients = to.meta.requiresClients || []

  const userAuthorized = store.getters['auth/isAuthorized']
  const userRoles = store.getters['auth/roles']
  const userPermissions = store.getters['auth/permissions']
  const userClient = store.state['client']

  if (requiresAuth) {
    if (!userAuthorized) {
      // route needs user to be authorized, but the user isn't authorized, so we will disallow the user
      return false
    }
  }

  if (requiresRoles.length > 0) {
    if (!userRoles.some((role) => requiresRoles.includes(role))) {
      // route needs user to have one role, but the user doesn't have it, so we will disallow the user
      return false
    }
  }

  if (requiresPermissions.length > 0) {
    if (!userPermissions.some((role) => requiresPermissions.includes(role))) {
      // route needs user to have one permission, but the user doesn't have it, so we will disallow the user
      return false
    }
  }

  if (userClient && requiresClients.length > 0) {
    if (!requiresClients.includes(userClient)) {
      // route needs user to have one client, but the user doesn't have it, so we will disallow the user
      return false
    }
  }

  // Every check was ok, so we will allow the user
  return true
}

export default router
